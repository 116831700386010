import React, { useEffect, useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import VerificationPopUp from "../../VerificationPop/VerificationPopUp";
import TextFiled from "../../../components/common/InputField/TextFiled";
import Popups from "../../../components/popup/Popups";

const Mobile = () => {
  const [verify, setVerify] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (verify) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [verify]);
  return (
    <>
      <form>
        <div className="grid grid-cols-12 w-fit mx-auto">
          <div className="col-span-12 ">
            <div>
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Mobile number"}
                placeholder={"Mobile number"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={" New mobile number"}
                placeholder={"Please enter your new mobile number"}
              />
            </div>
            <div className="flex items-center justify-end mt-10">
              <MainButton
                variant={"small"}
                type="button"
                onClick={() => setVerify(true)}
              >
                Verify
              </MainButton>
            </div>
          </div>
        </div>
      </form>
      {verify && (
        <>
          {verify && (
            <Popups
              setTrigger={() => setVerify(false)}
              ShowCrossButton={ShowCrossButton}
              popupWidth={"w-[350px] h-[450px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
            >
              <VerificationPopUp
                closePopup={() => setVerify(false)}
                firstMainHeading={"Verify your mobile number"}
                label={"Varification code"}
                placeholder={"Enter the 5-digit code here"}
                firstP={
                  "Please enter the verification code sent to your mobile phone. This step is crucial for securing your account and ensuring that you are the only one with access to it."
                }
                secondMainHeading={"Mobile verified"}
                secondP={
                  "Your mobile number has been verified! You're all set to receive timely updates and alerts directly to your phone. Thank you for helping us maintain the security of your account."
                }
              />
            </Popups>
          )}
        </>
      )}
    </>
  );
};
export default Mobile;
