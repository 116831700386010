import React from "react";
import Heading from "../../components/common/Typography/Heading";
import BigSlidesSlider from "../SuccessJourneySlider/BigSlidesSlider";

const SuccessSlider = () => {
  const data = [
    {
      rareHeading: "A polished profile that captures customer interest with enhanced visuals and detailed descriptions.",
      original: require("../../../src/assets/img/P1.jpeg"),
    },
    {
      rareHeading: "Showcase your business’s key successes, projects, and reviews to build trust and demonstrate expertise.",
      original: require("../../../src/assets/img/P2.jpeg"),
    },
    {
      rareHeading: "Achieve respect and acknowledgment in your business, boosting your reputation.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Build trust with a PRO badge that signifies quality and differentiates you from non-verified businesses.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Stand out from competitors with targeted keywords and professional presentation.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Higher visibility leads to more business opportunities, translating to more inquiries and customer conversions.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Highlight your best work photos, and customer reviews to attract new customers and reinforce your expertise.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
  ];
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-start text-offblack"}>
          Showcase your excellence
        </Heading>
      </div>
      <BigSlidesSlider data={data} rightAligning={true}/>
    </>
  );
};

export default SuccessSlider;
