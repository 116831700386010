import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";

const BusinessPortfolio = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Showcase your work professionally
        </Heading>
        <Paragraph variant={"MainParagraph"} className={"text-offblack mt-5"}>
          Display your portfolio using our integrated tools to highlight your
          expertise. Present your projects, skills, and achievements
          attractively to potential clients, showcasing your capabilities
          effectively.
        </Paragraph>
        <div className="h-[500px] sm:h-auto 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px] sm:aspect-[1264/700] mt-5 sm:mt-20 border-8 border-offblack rounded-3xl">
          {/* <img
            src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
            className="w-full h-[700px] rounded-[10px] object-cover shrink-0"
            alt="Tradesposters"
          /> */}
        </div>
      </div>
    </>
  );
};

export default BusinessPortfolio;
