import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import { ResendVerificationCode } from "../../Api/ResendVerificationCode";
import { SignUp as SignUpAction, VerificationCodeApi } from "../../Api/SignUp";
import TextFiled from "../../components/common/InputField/TextFiled";
import MainButton from "../../components/common/MainButton/MainButton";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import Spinner from "../../components/common/Spinner";
import Popup from "../../components/popup/Popup";
import VerificationPopUp from "../../sections/VerificationPop/VerificationPopUp";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import { MultiSelect } from "primereact/multiselect";
import Paragraph from "../../components/common/Typography/Paragraph";
import {
  InitialValues,
  validationSchema,
} from "../../FormValidations/SignUpSchema";
import useAuth from "../../Hooks/useAuth";
// import "./style.css";
import { useEffect } from "react";
// import { Dropdown } from "primereact/dropdown";
import Snippet from "../../components/common/SnippetIcon/Snippet";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../components/common/Typography/SpecialText";
// import InteractiveText from "../../components/common/Typography/InteractiveText";
const USER_TYPE = {
  CUSTOMER: "User",
  SELLLER: "Seller",
};
const LOGIN_SOURCE = {
  GMAIL: "Gmail",
  FACEBOOK: "Facebook",
  MANUAL: "Manual",
};
export const SignUp = ({ SignUpState, setSignUpState, SignIn, setSignIn }) => {
  const [EmailVerificationStatus, setEmailVerificationStatus] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  // const [validCaptcha, setValidCaptcha] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [UserType, setUserType] = useState("");
  const [popup, setPopup] = useState("A");
  const [PasswordType, setPasswordType] = useState("true");
  // const [RepeatPasswordType, setRepeatPasswordType] = useState("true");
  const [VerificationCode, setVerificationCode] = useState();
  const [userDetail, setUserDetail] = useState([{}]);
  const [ResendCode, setResendCode] = useState({
    isCodeSent: false,
  });
  const [VerificationError, setVerificationError] = useState({
    isError: false,
    errorMessage: null,
    isCodeExpire: false,
  });
  const { auth } = useAuth();
  const [RequestStart, setRequetStart] = useState(false);

  const formik = useFormik(
    {
      initialValues: InitialValues,
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setRequetStart(true);
        // const splitUserName = values.username.split(" ");
        // const firstName = splitUserName?.[0];
        // const lastName = splitUserName?.[1] ?? firstName;

        // if (!validCaptcha) {
        //   setRequetStart(false);
        //   toast.error("reCAPTCHA required!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     transition: Flip,
        //   });
        // } else {

        const SignUpUser = {
          FirstName: values.email,
          LastName: values.email,
          UserName: values.email,
          Email: values.email,
          ConfirmationCode: values.code,
          Password: values.password,
          ConfirmPassword: values.password,
          Source: LOGIN_SOURCE.MANUAL,
          EmailVerified: false,
          Role: UserType,
        };
        SignUpAction(SignUpUser)
          .then((response) => {
            if (response.status === 200) {
              setRequetStart(false);
              const accessToken = response?.data?.token;
              const roles = response?.data?.userRoles?.$values;
              const user = response?.data?.key;
              const expireAt = response?.data?.tokenExpire;
              const subscription = response?.data?.matrix?.user?.subscription;
              const userDetails = {
                accessToken,
                roles,
                user,
                expireAt,
                subscription,
              };
              console.log("Check", userDetails);
              setUserDetail(userDetails);
              setPopup("D");
              setShowCrossButton(true);
              setEmailVerificationStatus(!EmailVerificationStatus);

              formik.resetForm();
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      },
    }
    // }
  );

  function VerifyVerificationCodeHandle() {
    setRequetStart(true);
    console.log("Check", userDetail);
    if (!VerificationCode) {
      setRequetStart(false);
      setVerificationError({
        ...VerificationError,
        isError: true,
        errorMessage: "Verification code is required!",
      });
    } else if (VerificationCode) {
      VerificationCodeApi(userDetail?.user?.id, VerificationCode)
        .then((res) => {
          if (res.status === 200) {
            // console.log("Resp", res);
            setRequetStart(false);
            setSignUpState(!SignUpState);
            // setValidCaptcha(false);
            setVerificationCode(null);
            setEmailVerificationStatus(!EmailVerificationStatus);
            toast.success("Registration successfully ", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            setUserDetail({
              ...userDetail,
              user: {
                ...userDetail.user,
                emailConfirmed: true,
              },
            });
            setAuth({
              ...userDetail,
              user: {
                ...userDetail.user,
                emailConfirmed: true,
              },
            });
            localStorage.setItem(
              "LoginSession",
              JSON.stringify({
                ...userDetail,
                user: {
                  ...userDetail.user,
                  emailConfirmed: true,
                },
              })
            );
            if (UserType === USER_TYPE.SELLLER) {
              navigate({ pathname: "/Services" }, { replace: true });
            } else navigate({ pathname: from }, { replace: true });
          }
        })
        .catch((error) => {
          if (error.response.data.text === "CODEEXPIRE") {
            setRequetStart(false);
            setVerificationError({
              ...VerificationError,
              isError: true,
              errorMessage: error?.response?.data?.key ?? "Unkonwn Error!",
              isCodeExpire: true,
            });
          } else {
            setRequetStart(false);
            setVerificationError({
              ...VerificationError,
              isError: true,
              errorMessage: error?.response?.data?.key ?? "Unkonwn Error!",
            });
          }
        });
    }
  }
  function resendVerificationCodeHandler() {
    setRequetStart(true);
    ResendVerificationCode(userDetail?.user?.id)
      .then((res) => {
        if (res.status === 200) {
          setRequetStart(false);
          // toast.success("Verification Code Sent!", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   transition: Flip,
          // });
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    if (SignUpState) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignUpState]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const categories = [
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
  ];

  return (
    <>
      <ToastContainer autoClose={2000} />
      {SignUpState && (
        <>
          <Popup
            setTrigger={setSignUpState}
            modelType="SignUp"
            ShowCrossButton={ShowCrossButton}
            cross={true}
            onClose={() => {
              setSignUpState(false);
              setPopup("A");
            }}
          >
            <div>
              {RequestStart && <Spinner />}
              {/* Conditional Components Starts */}
              {popup === "A" && (
                <>
                  <div className="bg-pastalblue">
                    <Heading
                      variant={"h5"}
                      className={
                        "flex items-center justify-center mt-20 xl:mt-28"
                      }
                    >
                      Welcome to Tradesposter
                    </Heading>
                    <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                      <form>
                        <div className="flex justify-center">
                          <div className="w-fit mx-auto">
                            <TextFiled
                              label={"Email"}
                              variant={"medium"}
                              type="email"
                              name="email"
                              placeholder={"Enter your email address"}
                              onChange={formik?.handleChange}
                              value={formik?.values?.email}
                              className={"bg-white"}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-red-500">
                                <SpecialText
                                  variant={"FootNoteDisclaimer"}
                                  className={"mt-1"}
                                >
                                  {formik.errors.email}
                                </SpecialText>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="my-5 flex justify-center mx-[5%] md:mx-[0%">
                          <div className="w-fit mx-auto">
                            <label className="mb-2 block">
                              <AuxiliaryText
                                variant={"FieldLabel"}
                                className={"text-offblack"}
                              >
                                Password
                              </AuxiliaryText>
                            </label>
                            <PasswordField
                              passwordtype={PasswordType}
                              setpasswordtype={setPasswordType}
                              name="password"
                              placeholder={"Create a strong passward"}
                              onChange={formik?.handleChange}
                              value={formik?.values?.password}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="text-red-500">
                                <SpecialText
                                  variant={"FootNoteDisclaimer"}
                                  className={"mt-1"}
                                >
                                  {formik.errors.password}
                                </SpecialText>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="mt-2 flex justify-center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SECRET_KEY}
                          onChange={(value) => {
                            if (value) setValidCaptcha(true);
                          }}
                        />
                      </div> */}
                        <div className="flex justify-center mt-10">
                          <MainButton
                            variant={"large"}
                            onClick={
                              // formik.handleSubmit
                              () => {
                                setPopup("B");
                              }
                            }
                          >
                            Sign up
                          </MainButton>
                        </div>
                        <div className="flex items-center justify-center my-3 md:my-5">
                          <AuxiliaryText
                            variant={"Infographic"}
                            className={"text-center"}
                          >
                            or
                          </AuxiliaryText>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-5">
                          {/* <MainButton variant={"facebook"}>
                            <i className="fi fi-brands-facebook text-white size-4 pt-1"></i>
                            Continue with Facebook
                          </MainButton> */}
                          <MainButton variant={"google"}>
                            <i className="fi fi-brands-google text-white size-4 pt-1"></i>
                            {/* Continue with Google */}
                          </MainButton>
                        </div>
                        <div className="flex justify-center">
                          <SpecialText
                            variant={"FootNoteDisclaimer"}
                            className={
                              "mt-10 xl:w-[380px] 2xl:w-[380px] text-center"
                            }
                          >
                            By continuing, you're agree to tradesposter <br />
                            <span
                              className="text-primaryblue cursor-pointer"
                              onClick={() => {
                                setSignUpState(false);
                                navigate("/terms-&-conditions");
                              }}
                            >
                              Terms & Conditions.
                            </span>
                          </SpecialText>
                        </div>
                        <div class="border-t border-t-offblack w-[100px] my-2 mx-auto"></div>
                        {/* <hr className="bg-offblack h-px w-[100px] my-2 mx-auto" /> */}
                        <div className="flex justify-center">
                          <SpecialText
                            variant={"FootNoteDisclaimer"}
                            className={"text-center"}
                          >
                            Already have an account? <br />
                            <span
                              className="text-primaryblue cursor-pointer text-[10px]"
                              onClick={() => {
                                setSignUpState(false);
                                setSignIn(true);
                              }}
                            >
                              &nbsp;Sign in
                            </span>
                          </SpecialText>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* Sign Up form UI Ends */}
                </>
              )}

              {popup === "B" && (
                <>
                  <div className="bg-pastalblue">
                    <Heading
                      variant={"h5"}
                      className={
                        "flex items-center justify-center mt-20 xl:mt-28 text-offblack"
                      }
                    >
                      Choose your account
                    </Heading>
                    <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                      <div className="flex flex-col lg:flex-row justify-center items-center gap-8">
                        {/* Business */}
                        <div className="w-[350px] h-[250px] sm:w-[450px] sm:h-[250px] md:w-[500px] md:h-[300px] lg:w-[450px] lg:h-[300px] xl:w-[500px] xl:h-[300px] 2xl:w-[616px] 2xl:h-[350px] px-5 md:px-[56px] bg-white text-offblack rounded-3xl flex items-center">
                          <div>
                            <div className="mt-5">
                              <i className="fi fi-rs-briefcase text-[30px] xl:text-5xl 2xl:text-[50px] text-offblack hover:text-primaryblue"></i>
                            </div>
                            <div>
                              <SecondaryHeading
                                variant={"MainParagraphHeading"}
                                className={"text-offblack mt-3"}
                              >
                                Business
                              </SecondaryHeading>
                            </div>
                            <div className="mt-3">
                              <Paragraph
                                variant={"MainParagraph"}
                                className={
                                  "h-[54px] sm:h-[52px] md:h-[38px] lg:h-[40px] xl:h-[58px] 2xl:h-[50px] overflow-hidden text-ellipsis text-offblack"
                                }
                              >
                                For trade business aiming to partner with Trades
                                Poster, showcase their expertise, and broaden
                                their reach to a vast audience seeking quality
                                trade services.
                              </Paragraph>
                            </div>
                            <div className="flex justify-end mt-5 md:mt-10">
                              <MainButton
                                type="button"
                                variant={"outlinesmall"}
                                onClick={() => {
                                  setUserType(USER_TYPE.SELLLER);
                                  setPopup("C");
                                }}
                              >
                                Get started
                              </MainButton>
                            </div>
                          </div>
                        </div>
                        {/* Customer */}
                        <div className="w-[350px] h-[250px] sm:w-[450px] sm:h-[250px] md:w-[500px] md:h-[300px] lg:w-[450px] lg:h-[300px] xl:w-[500px] xl:h-[300px] 2xl:w-[616px] 2xl:h-[350px] px-5 md:px-[56px] bg-white text-offblack rounded-3xl flex items-center">
                          <div>
                            <div className="mt-5">
                              <i className="fi fi-rs-user text-[30px] xl:text-5xl 2xl:text-[50px] text-offblack hover:text-primaryblue"></i>
                            </div>
                            <div>
                              <SecondaryHeading
                                variant={"MainParagraphHeading"}
                                className={"text-offblack mt-3"}
                              >
                                Customer
                              </SecondaryHeading>
                            </div>
                            <div className="mt-3">
                              <Paragraph
                                variant={"MainParagraph"}
                                className={
                                  "h-[54px] sm:h-[52px] md:h-[38px] lg:h-[40px] xl:h-[58px] 2xl:h-[50px] overflow-hidden text-ellipsis text-offblack"
                                }
                              >
                                For those looking to find and hire skilled
                                professionals in various trades. Easily browse,
                                compare, and connect with trusted service
                                providers in your area.
                              </Paragraph>
                            </div>
                            <div className="flex justify-end mt-5 md:mt-10">
                              <MainButton
                                type="button"
                                variant={"outlinesmall"}
                                // className={` ${
                                //   UserType === USER_TYPE.CUSTOMER
                                //     ? "bg-black hover:bg-white hover:text-black text-white font-montserrat md:py-3  px-5  py-5  cursor-pointer rounded-lg font-bold text-[16px]"
                                //     : "bg-black hover:bg-primary hover:text-black text-white font-montserrat md:py-3  px-5  py-5  cursor-pointer rounded-lg font-bold text-[16px]"
                                // } `}
                                onClick={() => {
                                  setUserType(USER_TYPE.CUSTOMER);
                                  setPopup("C");
                                }}
                              >
                                Get started
                              </MainButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {popup === "C" && (
                <>
                  {UserType === USER_TYPE.CUSTOMER ? (
                    <>
                      <div className="bg-pastalblue">
                        <Heading
                          variant={"h5"}
                          className={
                            "flex items-center justify-center mt-20 xl:mt-28 text-offblack"
                          }
                        >
                          Customer basic profile
                        </Heading>

                        <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                          <form>
                            <div className="flex justify-center">
                              <div className="w-fit mx-auto">
                                <div>
                                  <TextFiled
                                    variant={"large"}
                                    label={"First name"}
                                    placeholder={"Enter your first name"}
                                    star={true}
                                  />
                                </div>
                                <div className="mt-5">
                                  <TextFiled
                                    variant={"large"}
                                    label={"Last name"}
                                    placeholder={
                                      "Enter your surname or an initial here."
                                    }
                                    snippet={true}
                                    SnippetText={
                                      "For our records and to personalize your experience, please provide either your full last name or simply its initials."
                                    }
                                  />
                                </div>
                                <div className="mt-5">
                                  <TextFiled
                                    variant={"large"}
                                    label={"Location"}
                                    placeholder={"Type your Suburb or post code"}
                                    star={true}
                                  />
                                </div>
                                <div className="flex items-center justify-end mt-10">
                                  <MainButton
                                    variant={"small"}
                                    onClick={() => {
                                      setPopup("D");
                                    }}
                                  >
                                    Submit
                                  </MainButton>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-pastalblue">
                        <Heading
                          variant={"h5"}
                          className={
                            "flex items-center justify-center mt-20 xl:mt-28 text-offblack"
                          }
                        >
                          Business basic profile
                        </Heading>
                        <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                          <form>
                            <div className="flex justify-center">
                              <div className="w-fit mx-auto">
                                <div>
                                  <TextFiled
                                    variant={"large"}
                                    label={"Business name"}
                                    placeholder={"Enter your business name"}
                                    // star={true}
                                  />
                                </div>
                                <div className="mt-5">
                                  <div className="flex">
                                    <Snippet
                                      variant={"label"}
                                      snippet={true}
                                      text={
                                        "Select the main category that best represents your business, for example, carpentry."
                                      }
                                      label={"Primary business category"}
                                    />
                                    <i class="fi fi-rs-medical-star text-offblack text-[6px] ml-1"></i>
                                  </div>
                                  <div className="custom-multiselect">
                                    <MultiSelect
                                      value={selectedCity}
                                      onChange={(e) => setSelectedCity(e.value)}
                                      options={categories}
                                      selectionLimit={1}
                                      showSelectAll={false}
                                      filter={false}
                                      filterBy="name"
                                      optionLabel="name"
                                      placeholder="Select business category"
                                      display="chip"
                                      className={`signUpCustomMultiselect bg-white text-offblack items-center focus:border block font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[50px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px] py-1 px-2.5 `}
                                    />
                                    <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack cursor-pointer custom-multiselect-icon"></i>
                                  </div>
                                </div>
                                <div className="mt-5">
                                  <div className="flex">
                                    <Snippet
                                      variant={"label"}
                                      snippet={true}
                                      text={
                                        "Select up to two secondary categories that further define your business, for example, framing or finished carpentry."
                                      }
                                      label={"Secondary business category"}
                                    />
                                    <i class="fi fi-rs-medical-star text-offblack text-[6px] ml-1"></i>
                                  </div>
                                  <div className="custom-multiselect">
                                    <MultiSelect
                                      value={selectedCountries}
                                      options={categories}
                                      onChange={(e) =>
                                        setSelectedCountries(e.value)
                                      }
                                      maxSelectedLabels={3}
                                      selectionLimit={2}
                                      showSelectAll={false}
                                      filter={false}
                                      filterBy="name"
                                      optionLabel="name"
                                      placeholder="Select secondary business category"
                                      display="chip"
                                      className={`bg-white text-offblack items-center focus:border block font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] py-1 px-2.5 `}
                                    />
                                    <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-multiselect-icon"></i>
                                  </div>
                                </div>
                                <div className="mt-5">
                                  <TextFiled
                                    variant={"large"}
                                    label={"Location"}
                                    placeholder={
                                      "Type your Suburb or post code"
                                    }
                                    star={true}
                                  />
                                </div>
                                <div className="flex items-center justify-end mt-10 w-full ">
                                  <MainButton
                                    variant={"small"}
                                    onClick={() => {
                                      setPopup("D");
                                    }}
                                  >
                                    Submit
                                  </MainButton>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {popup === "D" && (
                <div className="mt-20 lg:mt-28">
                  <VerificationPopUp
                    closePopup={() => {
                      setPopup("A");
                      setSignUpState(false);
                    }}
                    Headingh6={true}
                    Headingh5={true}
                    secondHeadingh6={true}
                    secondHeadingh5={true}
                    firstMainHeading={"Email verification"}
                    label={"Varification code"}
                    placeholder={"Input the code here."}
                    firstP={
                      "Check your email for the verification code from us. Enter it below to verify your email address, which is essential for account recovery and receiving important notifications."
                    }
                    secondMainHeading={"Email verified"}
                    secondP={
                      "Great job! Your email address has been verified. You will now receive important notifications and can securely manage your account. Stay connected for more updates and secure account management."
                    }
                  />
                </div>
              )}
            </div>

            {/* Conditional Components Ends */}
          </Popup>
        </>
      )}
    </>
  );
};
