import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Heading from "../../components/common/Typography/Heading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../components/common/Typography/SpecialText";

const EstimatedHighToLowCostGraph = () => {
  return (
    <>
      <>
        {/* Result Section */}
        <div className="mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mt-10 sm:mt-40 xl:mt-40">
          <div className="flex flex-col lg:flex-row items-center gap-x-16">
            <div className="lg:my-auto w-full lg:w-1/2">
              <Heading variant={"h2"} className={" text-offblack"}>
                Mixer <br className="hidden lg:block" /> Repair costs in{" "}
                <br className="hidden lg:block" /> Melbourne 3000
              </Heading>
            </div>
            <div className="w-[400px] md:w-1/2 relative">
              <div>
                <SecondaryHeading
                  variant={"MainParagraphHeading"}
                  className={"text-center text-offblack"}
                >
                  Price guide
                </SecondaryHeading>
              </div>
              <div className="flex justify-center mt-5">
                <SpecialText
                  variant={"ExtraLargeNumber"}
                  className={"text-offblack"}
                >
                  $50 - $96
                </SpecialText>
              </div>
              <div className="absolute p-2 -left-1 sm:-left-12 lg:-left-10 2xl:-left-1 3xl:left-10 2k:left-20 4k:left-24 mt-[200px] sm:mt-[170px] md:mt-[200px] lg:mt-[250px] w-[60px] z-20 ">
                <div className="bg-primaryblue text-white px-[2px] py-[1px] text-center">
                  <SpecialText variant={"Counter"}>$50</SpecialText>
                </div>
                <AuxiliaryText
                  variant={"FieldLabel"}
                  className={"text-center mt-1"}
                >
                  Low
                </AuxiliaryText>
              </div>
              <div className="absolute p-2 left-1/2 -ml-8 mt-5 w-[60px]">
                <div className="bg-primaryblue text-white px-[2px] py-[1px] text-center">
                  <SpecialText variant={"Counter"}>$78</SpecialText>
                </div>
                <AuxiliaryText
                  variant={"FieldLabel"}
                  className={"text-center mt-1"}
                >
                  Average
                </AuxiliaryText>
              </div>
              <div className="absolute p-2 -right-1 sm:-right-12 lg:-right-10 2xl:-right-1 3xl:right-10 2k:right-20 4k:right-24 mt-[200px] sm:mt-[170px] md:mt-[200px] lg:mt-[250px] w-[60px] z-20 ">
                <div className="bg-primaryblue text-white px-[2px] py-[1px] text-center">
                  <SpecialText variant={"Counter"}>$96</SpecialText>
                </div>
                <AuxiliaryText
                  variant={"FieldLabel"}
                  className={"text-center mt-1"}
                >
                  High
                </AuxiliaryText>
              </div>
              <div className="mt-20">
                <img
                  className="w-[300px] h-[200px] sm:w-[400px] sm:h-[150px] md:h-[200px] lg:h-[250px] mx-auto"
                  src={require("../../assets/img/cost-graph.png")}
                  alt="cost-estimation-graph"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default EstimatedHighToLowCostGraph;
