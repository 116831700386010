import React, { useEffect } from "react";
import { useState } from "react";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import Popups from "../../../components/popup/Popups";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import QuestionAdditionForm from "../../../components/common/BusinessFAQs/QuestionAdditionForm";

const BusinessFAQs = () => {
  const [FAQs, setFAQs] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const questions = [
    {
      qst: "Lorem ipsum dolor sit amet consectetur  ?",
    },
    {
      qst: "Lorem ipsum dolor sit amet consectetur  ?",
    },
  ];
  useEffect(() => {
    if (FAQs) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [FAQs]);
  return (
    <>
      <div className="flex">
        <Snippet
          variant={"label"}
          label={"Business FAQs"}
          snippet={true}
          text={
            "Provide up to 5 tailored FAQs specific to your business operations."
          }
        />
        <i class="fi fi-rs-medical-star text-offblack text-[6px] ml-1"></i>
      </div>

      <button
        type="button"
        className="bg-white lg:bg-pastalblue flex items-center gap-x-3 w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] rounded-lg  p-3 mb-2"
        onClick={() => setFAQs(true)}
      >
        <i className="fi fi-rs-plus text-xs text-offblack"></i>
        <AuxiliaryText variant={"Placeholder"} className={"text-lightgray"}>
          Add five personalised faqs
        </AuxiliaryText>
      </button>
      {questions.map((e) => {
        return (
          <>
            <div className="bg-white lg:bg-pastalblue flex items-center justify-between w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] rounded-lg p-3 mb-2">
              <AuxiliaryText
                variant={"Placeholder"}
                className={"text-offblack"}
              >
                {e.qst}
              </AuxiliaryText>
              <i className="fi fi-rs-trash text-base text-alertred"></i>
            </div>
          </>
        );
      })}
      <button
        type="button"
        className="flex items-center gap-x-3 text-primaryblue mt-5"
        onClick={() => setFAQs(true)}
      >
        <i className="fi fi-rs-plus text-xs"></i>
        <InteractiveText variant={"ActionLink"}>
          Add more questions
        </InteractiveText>
      </button>
      {FAQs && (
        <>
          <Popups
            setTrigger={() => setFAQs(false)}
            ShowCrossButton={ShowCrossButton}
            popupWidth={
              "w-[350px] h-[500px] sm:w-[600px] sm:h-[550px] 2xl:w-[600px] 2xl:h-[550px] pb-10"
            }
            crossPosition={
              "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
            }
          >
            <QuestionAdditionForm closePopup={() => setFAQs(false)} />
          </Popups>
        </>
      )}
    </>
  );
};

export default BusinessFAQs;
