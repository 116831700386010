import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const FounderMessage = () => {
  return (
    <>
      {/* Founder Message */}
      <div className="flex flex-col lg:flex-row items-center gap-x-16 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40">
          <div className="order-1 shrink-0 w-full h-auto lg:w-1/2 lg:h-[500px] aspect-square lg:aspect-auto">
            <img
              className="object-cover w-full h-full rounded-3xl"
              src={require("../../assets/img/TradesPosterSpeciality/businesses.jpg")}
              alt="trade-poster"
            />
          </div>
          <div className="mt-7 sm:mt-10 lg:my-auto order-2">
            <div>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"text-offblack mt-5 lg:mt-0 "}
            >
              In founding TradesPoster, I envisioned a marketplace where
              integrity and excellence lead. We commit to unparalleled service
              and connections in Australia's trades sector, ensuring quality and
              trust in every interaction.
            </SecondaryHeading>
            <div className="mt-2">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                Saim , Founder
              </Paragraph>
            </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default FounderMessage;
