import React from "react";
import Heading from "../../components/common/Typography/Heading";
const MainBanner = () => {
  return (
    <>
      <div
        className={`w-[100%] h-screen sm:h-[1000px] lg:h-screen relative bg-no-repeat bg-center object-cover bg-cover main-banner`}
      >
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] h-full ">
          <Heading
            variant={"h1"}
            className={"text-start text-white w-full pt-[50vh] sm:pt-[50vh] md:pt-[50vh] lg:pt-[50vh] xl:pt-[50vh] 2xl:pt-[40vh]"}
          >
           Connect. Collaborate. Complete.
          </Heading>
        </div>
      </div>
    </>
  );
};
export default MainBanner;
