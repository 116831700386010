import React from "react";
import Heading from "../../components/common/Typography/Heading";
import { Link } from "react-router-dom";
import NCC from "../../assets/img/regulators/NCC.png";
import STD from "../../assets/img/regulators/STD.png";
import HIA from "../../assets/img/regulators/HIA.png";
import MBA from "../../assets/img/regulators/MBA.png";
import VBA from "../../assets/img/regulators/VBA.png";
import QBCC from "../../assets/img/regulators/QBCC.png";
import CBOS from "../../assets/img/regulators/CBOS.jpg";
import DMIRS from "../../assets/img/regulators/DMIRS.png";
import GSA from "../../assets/img/regulators/GSA.gif";
import NSWG from "../../assets/img/regulators/NSWG.png";
import NTG from "../../assets/img/regulators/NTG.png";
import ACTPlanning from "../../assets/img/regulators/ACT-Planning.png";

const Regulators = () => {
  const regulators = [
    {
      icon: NCC,
      link: "https://ncc.abcb.gov.au",
    },
    {
      icon: STD,
      link: "https://www.standards.org.au",
    },
    {
      icon: HIA,
      link: "https://hia.com.au",
    },
    {
      icon: MBA,
      link: "https://masterbuilders.com.au",
    },
    {
      icon: VBA,
      link: "https://www.vba.vic.gov.au/",
    },
    {
      icon: NSWG,
      link: "https://www.fairtrading.nsw.gov.au",
    },
    {
      icon: QBCC,
      link: "https://www.qbcc.qld.gov.au/",
    },
    {
      icon: DMIRS,
      link: "https://www.dmirs.wa.gov.au/",
    },
    {
      icon: GSA,
      link: "https://www.cbs.sa.gov.au/",
    },
    {
      icon: CBOS,
      link: "https://www.cbos.tas.gov.au/",
    },
    {
      icon: NTG,
      link: "https://bpb.nt.gov.au",
    },
    {
      icon: ACTPlanning,
      link: "https://www.planning.act.gov.au",
    },
  ];
  return (
    <>
      <div className="text-offblack mt-10 sm:mt-40 mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px]">
        <Heading variant={"h2"} className={"text-offblack"}>
          Plumbing regulators in Australia
        </Heading>
        <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 place-items-center items-center justify-center mt-7 sm:mt-10 lg:mt-20 gap-10 sm:gap-16 w-full">
          {regulators.map((item) => {
            return (
              <>
                <div className="w-[100px] h-[100px] relative">
                <Link to={item.link} target="_blank">
                  <img
                   src={item.icon}
                    className="w-full h-full object-contain"
                    alt={item.link}
                  />
                  </Link>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Regulators;
