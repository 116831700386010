import React from "react";
import SecondaryHeading from "../../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../../components/common/Typography/Paragraph";

const NotificationsLayout = () => {
  const Notificationdata = [
    {
      heading: "Account and Security",
      description:
        "Notifications for login alerts, security updates, and password change confirmations.",
      status: "Enabled",
    },
    {
      heading: "Account Security Alerts",
      description: "Get instant alerts for new sign-ins and security updates.",
      status: "Enabled",
    },
    {
      heading: "Business Performance Insights",
      description: "Weekly summaries of customer interactions and analytics.",
      status: "Enabled",
    },
    {
      heading: "Profile Enhancement Notifications",
      description: "Real-time updates on any changes to your business profile.",
      status: "Enabled",
    },
    {
      heading: "Customer Engagement Alerts",
      description: "Direct messages from potential clients at your fingertips.",
      status: "Enabled",
    },
    {
      heading: "Marketplace Competitiveness Updates",
      description: "Market trends and competitor insights directly to you.",
      status: "Enabled",
    },
    {
      heading: "Regulatory Compliance Notifications",
      description: "Stay informed on legal changes impacting your services.",
      status: "Enabled",
    },
    {
      heading: "Billing and Subscription Reminders",
      description:
        "Upcoming payment alerts to avoid any service interruptions. ",
      status: "Enabled",
    },
    {
      heading: "Customer Review Notifications",
      description: "Immediate feedback when customers review your services.",
      status: "Enabled",
    },
    {
      heading: "Trades Poster Announcements",
      description:
        "Important policy updates and system information from Trades Poster.",
      status: "Enabled",
    },
    {
      heading: "Data Privacy and Consent Updates",
      description:
        "Privacy policy changes and reminders about data consent requirements.",
      status: "Enabled",
    },
    // {
    //   heading: "security privacy about Data sharing ",
    //   description:
    //     "",
    //   status: "Enabled",
    // },
  ];
  return (
    <>
          <div className="lg:mx-auto lg:max-w-[800px] 2xl:w-[600px] 3xl:w-[700px] 2k:w-[800px] 4k:w-[900px]">
        <div>
          {Notificationdata.map((item, index) => {
            return (
              <>
                <div key={index} className="flex justify-between gap-x-5 mb-10">
                  <div>
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={"text-offblack"}
                    >
                      {item.heading}
                    </SecondaryHeading>
                    <Paragraph
                      variant={"MainParagraph"}
                      className={"mt-2 text-offblack"}
                    >
                      {item.description}
                    </Paragraph>
                  </div>
                  <div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer ml-5"
                      />
                      <div className="w-9 h-5 bg-lightgray rounded-full peer-checked:after:translate-x-full after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-lightgray after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primaryblue"></div>
                    </label>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NotificationsLayout;
