import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import ButtonText from "../common/Typography/ButtonText";
import InteractiveText from "../common/Typography/InteractiveText";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  lineTension: 0.05, // this prevents sharp corners
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      // text: "Chart.js Line Chart - Multi Axis"
    },
    legend: {
      display: false, // Set display to false to hide the legend
      align: "start",
      position: "top",
      labels: {
        font: {
          family: "PT Sans",
          size: "12px",
        },
      },
    },
    tooltip: {
      backgroundColor: "#E8F0FF", // Set the background color of the tooltip
      bodyColor: "#232A2E", // Set the text color of the tooltip body
      titleColor: "#232A2E", // Set the text color of the tooltip title (heading)
      titleFontFamily: "IBM Plex Sans Condensed", // Set the font family of the tooltip title
      bodyFontFamily: "PT Sans", // Set the font family of the tooltip body
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
  },

  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      grid: {
        display: false,
      },
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
    x: {
      grid: { display: false },
      ticks: {
        autoSkip: true,
        // minTicksLimit:3,
        maxTicksLimit: 6,
      },
    },
  },
};

const labels = [
  "Sep-1",
  "Sep-2",
  "Sep-3",
  "Sep-4",
  "Sep-5",
  "Sep-6",
  "Sep-7",
  "Sep-8",
  "Sep-9",
  "Sep-10",
  "Sep-11",
  "Sep-12",
  "Sep-13",
  "Sep-14",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Saved",
      FontFace: "PT Sans",
      data: labels.map(() => Math.round(Math.random() * 100)),
      borderColor: "#03A62C",
      backgroundColor: "#03A62C",
      yAxisID: "y",
    },
    {
      label: "Views",
      data: labels.map(() => Math.round(Math.random() * 100)),
      borderColor: "#2E00FF",
      backgroundColor: "#2E00FF",
      yAxisID: "y1",
    },
    {
      label: "Share",
      data: labels.map(() => Math.round(Math.random() * 100)),
      borderColor: "#7B62D9",
      backgroundColor: "#7B62D9",
      yAxisID: "y1",
    },
  ],
};

export default function LineChart() {
  return (
    <>
      <div class="hidden md:block border-b-2 border-lightgray md:w-[700px] lg:w-fit mt-5">
        <ul class="flex flex-wrap -mb-px text-center text-lightgray">
          <li>
            <Link className="flex items-center justify-center gap-2 pb-2 h-5 pl-2.5 pr-10 md:pr-20 border-b-2 border-transparent text-primaryblue hover:text-primaryblue border-b-primaryblue hover:border-primaryblue group">
              {/* <i className="fi fi-rs-heart text-xs cursor-pointer text-primaryblue"></i> */}
              <InteractiveText variant={"FAQHeader"}>
                Featured post metrics
              </InteractiveText>
              {/* <SpecialText
                    variant={"TabsCounter"}
                    className={"flex items-center justify-center"}
                  >
                    23
                  </SpecialText> */}
            </Link>
          </li>
          <li>
            <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-10 md:pr-20 border-b-2 border-transparent hover:text-primaryblue hover:border-primaryblue group">
              {/* <i className="fi fi-rr-share-alt-square text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
              <InteractiveText variant={"FAQHeader"}>
                Business profile metrics
              </InteractiveText>
              {/* <SpecialText
                    variant={"TabsCounter"}
                    className={"flex items-center justify-center"}
                  >
                    5
                  </SpecialText> */}
            </Link>
          </li>
          <li>
            <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-10 md:pr-20 border-b-2 border-transparent hover:text-primaryblue hover:border-primaryblue group">
              {/* <i className="fi fi-rr-share-alt-square text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
              <InteractiveText variant={"FAQHeader"}>
                Regular post metrics
              </InteractiveText>
              {/* <SpecialText
                    variant={"TabsCounter"}
                    className={"flex items-center justify-center"}
                  >
                    8
                  </SpecialText> */}
            </Link>
          </li>
        </ul>
      </div>

      <div className="h-60 md:h-96 lg:w-full lg:h-[350px] xl:h-[400px] 3xl:h-[450px] 2k:h-[500px] 4k:h-[550px]">
        <Line
          options={options}
          data={data}
          plugins={[
            {
              id: "increase-legend-spacing",
              beforeInit(chart) {
                // Get reference to the original fit function
                const originalFit = chart.legend.fit;
                // Override the fit function
                chart.legend.fit = function fit() {
                  // Call original function and bind scope in order to use `this` correctly inside it
                  originalFit.bind(chart.legend)();
                  this.height += 20;
                };
              },
            },
          ]}
        />
      </div>
    </>
  );
}
