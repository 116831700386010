import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../components/common/Typography/SpecialText";

const Article = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] pt-28 sm:pt-48">
        <Heading variant={"h1"} className={" text-offblack text-center"}>
          Blog title here
        </Heading>
        <div className="xl:w-[1100px] xl:h-[600px] 2xl:w-[1264px] 2xl:h-[700px] 3xl:w-[1400px] 3xl:h-[800px] 2k:w-[1600px] 2k:h-[900px] 4k:w-[1800px] 4k:h-[1000px] aspect-[1264/700] mt-10 sm:mt-20">
          <img
            className="w-full h-full rounded-3xl"
            src={require("../../assets/img/default-image.jpg")}
            alt="trade-poster"
          />
        </div>
        <div>
          <div className="mt-10">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              The services that an architect typically provides include concept
              design development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration.
            </Paragraph>
          </div>
          <div className="mt-10">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              The services that an architect typically provides include concept
              design development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management.
            </Paragraph>
          </div>
          <div className="mt-10">
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"text-offblack"}
            >
              Heading 1
            </SecondaryHeading>
          </div>
          <div className="my-3">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              The services that an architect typically provides include concept
              design development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration. Architects also provide a wide
              variety of additional services including feasibility studies,
              architectural programming and project management. The services
              that an architect typically provides include concept design
              development, preparation of construction documents, and
              construction administration.
            </Paragraph>
          </div>
          <div className="mt-10">
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"text-offblack"}
            >
              Heading 2
            </SecondaryHeading>
          </div>
          <div className="my-3">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              The services that an architect typically provides include
            </Paragraph>
          </div>
          <ul className="list-inside list-decimal flex flex-col gap-3">
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
          </ul>
          <div className="mt-10">
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"text-offblack"}
            >
              Heading 3
            </SecondaryHeading>
          </div>
          <div className="my-3">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              The services that an architect typically provides include
            </Paragraph>
          </div>
          <ul className="list-inside list-decimal flex flex-col gap-3">
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
            <li className="pl-5">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                The services that an architect typically provides include
                concept design development, preparation of construction
                documents, and construction administration. Architects also
                provide construction documents, and construction administration.
                Architects also provide a.
              </Paragraph>
            </li>
          </ul>
          <div className="my-10">
            <SpecialText
              variant={"ProfileName"}
              className={"mt-5 text-offblack"}
            >
              Katy Vervack
            </SpecialText>
            <div className="flex items-center justify-start gap-2 mt-3">
              <i className="fi fi-brands-facebook text-base cursor-pointer"></i>
              <i className="fi fi-brands-twitter text-base cursor-pointer"></i>
              <i className="fi fi-brands-linkedin text-base cursor-pointer"></i>
              <i className="fi fi-rr-share-alt-square text-base cursor-pointer"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;
