import { useFormik } from "formik";
import React, { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import { SignIn as SignInAction } from "../../Api/SignUp";
import TextFiled from "../../components/common/InputField/TextFiled";
import MainButton from "../../components/common/MainButton/MainButton";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import Heading from "../../components/common/Typography/Heading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import Spinner from "../../components/common/Spinner";
import Popup from "../../components/popup/Popup";
import VerificationPopUp from "../../sections/VerificationPop/VerificationPopUp";

import {
  InitialValues,
  validationSchema,
} from "../../FormValidations/SignInSchema";
import useAuth from "../../Hooks/useAuth";
import { useEffect } from "react";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import SpecialText from "../../components/common/Typography/SpecialText";
const Login = ({
  SignIn,
  setSignIn,
  SignUpState,
  setSignUpState,
  location: redirect,
  // userDetails,
}) => {
  const [PasswordType, setPasswordType] = useState("true");
  // const [validCaptcha, setValidCaptcha] = useState(false);
  const [VerificationError, setVerificationError] = useState({
    isCredentialsMatched: false,
  });
  const USER_TYPE = {
    CUSTOMER: "Customer",
    SELLLER: "Seller",
  };
  const [RequestStart, setRequetStart] = useState(false);
  // const [UserType, setUserType] = useState(USER_TYPE.CUSTOMER);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname;
  if (location?.state?.from?.pathname) from = location.state.from.pathname;
  else if (location?.pathname) from = location.pathname;
  else if (redirect) from = redirect;
  else from = "CustomerDashboard";
  const formik = useFormik(
    {
      initialValues: InitialValues,
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setRequetStart(true);
        // if (!validCaptcha) {
        //   setRequetStart(false);
        //   toast.error("reCAPTCHA required!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     transition: Flip,
        //   });
        // } else {

        const SignInUser = {
          Email: values.email,
          Password: values.password,
          ConfirmPassword: values.password,
        };
        SignInAction(SignInUser)
          .then((response) => {
            if (response.status === 200) {
              const accessToken = response?.data?.token;
              const expireAt = response?.data?.tokenExpire;
              const roles = response?.data?.userRoles?.$values;

              const user = response?.data?.key;
              const subscription = response?.data?.matrix?.subscription;
              const userDetails = {
                accessToken,
                roles,
                user,
                expireAt,
                subscription,
              };
              setRequetStart(false);
              localStorage.setItem("LoginSession", JSON.stringify(userDetails));
              setAuth(userDetails);
              setSignIn(!SignIn);
              // console.log("UserDetails", userDetails);
              // setValidCaptcha(false);
              navigate("/");
              if (
                roles?.filter((e) => e.roleName === USER_TYPE.SELLLER).length >
                0
              ) {
                navigate({ pathname: "/Services" }, { replace: true });
              } else navigate({ pathname: from }, { replace: true });
            }
          })
          .catch((error) => {
            setRequetStart(false);
            toast.error("Username or Password is Incorrect", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          });
      },
    }
    // }
  );

  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (SignIn || SignUpState) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignIn, SignUpState]);
  return (
    <>
      <ToastContainer autoClose={2000} />
      {SignIn && (
        <Popup
          onClose={() => {
            navigate("/");
            setStep(1);
          }}
          setTrigger={setSignIn}
          modelType="SignIn"
          ShowCrossButton={ShowCrossButton}
          cross={true}
          // popupWidth={"md:w-[650px]"}
          // crossPosition={"md:w-[650px]"}
        >
          {step === 1 && (
            <>
              <div>
                <Heading
                  variant={"h5"}
                  className={
                    "flex items-center justify-center text-offblack mt-20 xl:mt-28"
                  }
                >
                  Login
                </Heading>
                <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                  {RequestStart && <Spinner />}
                  <form>
                    <div className="flex justify-center mx-[5%] md:mx-auto">
                      <div className="w-fit mx-auto">
                        <TextFiled
                          className={"bg-white"}
                          label={"Email"}
                          variant={"medium"}
                          type="email"
                          name="email"
                          placeholder={"Enter your registered email"}
                          onChange={formik?.handleChange}
                          value={formik?.values?.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-alertred">
                            <SpecialText
                              variant={"FootNoteDisclaimer"}
                              className={"mt-1"}
                            >
                              {formik.errors.email}
                            </SpecialText>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="my-5 flex justify-center mx-[5%] md:mx-0">
                      <div className="w-fit mx-auto">
                        <label className="mb-2 block">
                          <AuxiliaryText
                            variant={"FieldLabel"}
                            className={"text-offblack"}
                          >
                            Password
                          </AuxiliaryText>
                        </label>
                        <PasswordField
                          passwordtype={PasswordType}
                          setpasswordtype={setPasswordType}
                          name="password"
                          placeholder={"Enter your associated passward"}
                          onChange={formik?.handleChange}
                          value={formik?.values?.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-alertred">
                            <SpecialText
                              variant={"FootNoteDisclaimer"}
                              className={"mt-1"}
                            >
                              {formik.errors.password}
                            </SpecialText>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="mt-2 flex justify-center">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SECRET_KEY}
                      onChange={(value) => {
                        if (value) setValidCaptcha(true);
                      }}
                    />
                  </div> */}
                    <div className="flex justify-center mt-10">
                      <MainButton
                        variant={"large"}
                        onClick={formik.handleSubmit}
                      >
                        Login
                      </MainButton>
                    </div>
                    <div className="flex justify-center mt-3">
                      <div className="lg:w-[400px] text-center">
                        <button
                          className="text-primaryblue cursor-pointer"
                          type="button"
                          onClick={() => setStep(2)}
                        >
                          <InteractiveText variant={"ActionLink"}>
                            Forgot password
                          </InteractiveText>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-center my-3 md:my-5">
                      <AuxiliaryText
                        variant={"Infographic"}
                        className={"text-center"}
                      >
                        or
                      </AuxiliaryText>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-5">
                      {/* <MainButton variant={"facebook"}>
                        <i className="fi fi-brands-facebook text-white size-4 pt-1"></i>
                        Continue with Facebook
                      </MainButton> */}
                      <MainButton variant={"google"}>
                        <i className="fi fi-brands-google text-white size-4 pt-1"></i>
                        {/* Continue with Google */}
                      </MainButton>
                    </div>
                    <div className="flex justify-center">
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"mt-10 xl:w-[380px] text-center"}
                      >
                        By continuing, you're agree to tradesposter <br />
                        <span
                          className="text-primaryblue cursor-pointer text-[10px]"
                          onClick={() => {
                            setSignIn(false);
                            navigate("/terms-&-conditions");
                          }}
                        >
                          Terms & Conditions.
                        </span>
                      </SpecialText>
                    </div>
                    <div class="border-t border-t-offblack w-[100px] my-2 mx-auto"></div>
                    {/* <hr className="bg-offblack h-px w-[100px] my-2 mx-auto" /> */}
                    <div className="flex justify-center">
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"text-center"}
                      >
                        Don't have an account? <br />
                        <span
                          className="text-primaryblue cursor-pointer"
                          onClick={() => {
                            setSignIn(!SignIn);
                            setSignUpState(!SignUpState);
                          }}
                        >
                          &nbsp;Sign up
                        </span>
                      </SpecialText>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="my-auto mx-auto">
                <Heading
                  variant={"h5"}
                  className={
                    "flex items-center justify-center mt-10 md:mt-28 text-offblack"
                  }
                >
                  Password recovery
                </Heading>
                <div className="h-[500px] overflow-auto w-fit mx-auto pt-10 pb-20 hidden-scrollbar">
                  {RequestStart && <Spinner />}
                  <form>
                    <div>
                      <TextFiled
                        label={"Email"}
                        variant={"large"}
                        type="email"
                        name="email"
                        placeholder={"Enter your registered email"}
                        // onChange={formik?.handleChange}
                        // value={formik?.values?.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-red-500">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div className="my-5">
                      <TextFiled
                        label={"Create New Password"}
                        variant={"large"}
                        type="email"
                        name="email"
                        placeholder={"New Password"}
                        // onChange={formik?.handleChange}
                        // value={formik?.values?.email}
                      />
                      {/* {formik.touched.email && formik.errors.email ? (
                        <div className="text-red-500">
                          {formik.errors.email}
                        </div>
                      ) : null} */}
                    </div>

                    <div>
                      <TextFiled
                        label={"Confirm Password"}
                        variant={"large"}
                        type="email"
                        name="email"
                        placeholder={"Confirm New Password"}
                        // onChange={formik?.handleChange}
                        // value={formik?.values?.email}
                      />
                      {/* {formik.touched.email && formik.errors.email ? (
                        <div className="text-red-500">
                          {formik.errors.email}
                        </div>
                      ) : null} */}
                    </div>

                    <div className="flex justify-end mt-10">
                      <MainButton
                        type="button"
                        variant={"small"}
                        onClick={() => {
                          setStep(3);
                        }}
                      >
                        Update
                      </MainButton>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {step === 3 && (
            <div className="mt-28">
              <VerificationPopUp
                Headingh6={true}
                Headingh5={true}
                secondHeadingh6={true}
                secondHeadingh5={true}
                closePopup={() => setStep(1)}
                firstMainHeading={"Identity verification"}
                label={"Varification code"}
                placeholder={"Input the code here."}
                firstP={
                  "Please visit your registered email inbox and find out the verification code receievd from tradesposter"
                }
                secondMainHeading={"Password updated successfully !!!"}
                secondP={
                  "Great job! Your email address has been verified. You will now receive important notifications and can securely manage your account. Stay connected for more updates and secure account management."
                }
              />
            </div>
          )}
        </Popup>
      )}
    </>
  );
};

export default Login;
