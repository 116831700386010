import { Outlet, useLocation } from "react-router-dom";
import { Footer, Header } from "../ComponentFile";
import Sidebar from "./Sidebar/Sidebar";
import CustomerSidebar from "./Sidebar/CustomerSidebar";
import CustomerHeader from "./Header/CustomerHeader";
import useAuth from "../Hooks/useAuth";

const Layout = () => {
  const location = useLocation();
  const { auth } = useAuth();
  // console.log("roles", auth?.roles[0]?.roleName)
  if (location?.pathname?.includes("business-dashboard")) {
    return (
      <main className="App lg:grid lg:grid-rows-[0fr,_1fr] bg-pastalblue pb-40">
        <Header isSellerDashboard />
        <div className="lg:grid lg:grid-cols-[0fr,_1fr] lg:mx-auto lg:w-[950px] xl:w-[1150px] 2xl:w-[1400px] 3xl:w-[1596px] 2k:w-[1780px] 4k:w-[1880px] hidden-scrollbar mt-5 lg:mt-16 gap-8">
            <div className="hidden lg:block">
            <Sidebar />
          </div>
          <div className="mx-[5%] lg:mx-auto lg:w-[700px] xl:w-[868px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] pb-20">
              <Outlet />
          </div>
        </div>
      </main>
    );
  } else if (location?.pathname?.includes("customer-dashboard")) {
    return (
      <main className="App lg:grid lg:grid-rows-[0fr,_1fr] bg-pastalblue pb-40">
        <CustomerHeader isCustomerDashboard />
        <div className="lg:grid lg:grid-cols-[0fr,_1fr] lg:mx-auto lg:w-[950px] xl:w-[1150px] 2xl:w-[1400px] 3xl:w-[1596px] 2k:w-[1780px] 4k:w-[1880px] hidden-scrollbar mt-5 lg:mt-16 gap-8">
            <div className="hidden lg:block">
              <CustomerSidebar />
            </div>
            <div className="mx-[5%] lg:mx-auto lg:w-[700px] xl:w-[868px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] pb-20">
              <Outlet />
            </div>
          </div>
      </main>
    );
  } else if (!auth?.user) {
    return (
      <main className="App">
        <Header />
        <Outlet />
        {!auth?.user && <>{location.pathname !== "/" && <Footer />}</>}
      </main>
    );
  } else {
    return (
      <main className="App bg-pastalblue pb-40">
       {/* {
          auth?.roles?.lenght === 1 ? auth?.roles?.find(f=>f.roleName === "User") ? (<CustomerHeader/>) :(<Header/>) : (<Header />)
        }  */}

        {auth?.roles?.find((f) => f.roleName === "User") ? (
          <CustomerHeader />
        ) : (
          <Header />
        )}

        <Outlet />
      </main>
    );
  }
};

export default Layout;
