import React from "react";
import HeadingsWithParagraphsList from "../sections/ServiceGuide/HeadingsWithParagraphsList";
import CommonFAQs from "../sections/ServiceGuide/CommonFAQs";
import RelatedArticles from "../sections/ServiceGuide/RelatedArticles";
import PricingEstimates from "../sections/ServiceGuide/PricingEstimates";
import Regulators from "../sections/ServiceGuide/Regulators";
import CheckList from "../sections/ServiceGuide/CheckList";
import WhyProfessional from "../sections/ServiceGuide/WhyProfessional";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";
import { useNavigate } from "react-router-dom";
import PopularTypes from "../sections/ServiceGuide/PopularTypes";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const ServiceGuide = () => {
  const navigate = useNavigate();
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 2k:w-[1400px] 4k:w-[1600px]"
        }
        heading={
          "Optimize Your Comfort with Expert Air Conditioning and Heating Services"
        }
        className={"bg-about"}
      >
        <div className="flex items-center justify-center sm:justify-start mt-10">
          <MainButton
            variant={"large"}
            onClick={() => navigate("/services-guide")}
          >
            Explore Services
          </MainButton>
        </div>
      </Banner>
      <HeadingsWithParagraphsList />
      <WhyProfessional />
      <PopularTypes />
      <CheckList />
      <PricingEstimates />
      <Regulators />
      <CommonFAQs />
      <RelatedArticles />
      <FullLengthSlider />
    </>
  );
};

export default ServiceGuide;
