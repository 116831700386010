import React, { useRef } from "react";
import { useSelector } from "react-redux";
import CircularButton from "../../../../components/common/MainButton/CircularButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import PriceCard from "../../../../components/common/PriceCard/PriceCard";
import { useLocation } from "react-router-dom";

const PricingSlider = ({ showheading }) => {
  const getFreePackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "FREE")
  );
  const getBusinessPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "BUSINESS")
  );
  const getPremiumPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "PREMIUM")
  );
  const swiperRef = useRef();
  const location = useLocation();
  return (
    <>
    <div>
      <Swiper
        className="w-[300px]"
        slidesPerView={1}
        loop
        speed={500}
        spaceBetween={20}
        modules={[Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide>
          <PriceCard
            bottomLine={"border-b border-b-mediumgray pb-3 sm:pb-5"}
            MainDiveClasses={
              "bg-white lg:bg-pastalblue text-offblack w-[300px] h-[450px] sm:w-[350px] sm:h-[550px]"
            }
            pricingColor={"text-offblack"}
            tagging={true}
            label={"Free"}
            price={"0"}
            duration={1}
            description={
              "Perfect for new ventures ready to connect with customers. Start with Trades Poster, create your profile, no fees attached."
            }
            buttonText={"Get started"}
            listItems={[
              `Business Description`,
              `Profile logo`,
              `Live chat`,
              // `Business ABN details`,
              // `Cover picture`,
              `1 Regular post`,
              `1 Keyword`,
            ]}
            // listItemsValue={[
            //   `${getPremiumPackageDetail?.regularListing}`,
            //   `${getPremiumPackageDetail?.featureListing}`,
            //   `${getPremiumPackageDetail?.noOfImages}`,
            // ]}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            bottomLine={"border-b border-b-mediumgray pb-3 sm:pb-5"}
            MainDiveClasses={
              "bg-white lg:bg-pastalblue text-offblack w-[300px] h-[450px] sm:w-[350px] sm:h-[550px]"
            }
            pricingColor={"text-offblack"}
            tagging={true}
            label={"Standard"}
            price={"0"}
            duration={1}
            description={
              "Best for businesses wanting more visibility and trust. The Standard plan adds powerful features to engage and grow."
            }
            buttonText={"Get started"}
            listItems={[
              `Includes all free plan features`,
              `Portfolio gallery`,
              // `multiple category selection`,
              // `Business associations`,
              `5 Keywords`,
              `5 Featured posts`,
              // `8 Portfolio pictures`,
              // `3 Post pictures`,
              `Unlimited regular posts`,
            ]}
            // listItemsValue={[
            //   `${getPremiumPackageDetail?.regularListing}`,
            //   `${getPremiumPackageDetail?.featureListing}`,
            //   `${getPremiumPackageDetail?.noOfImages}`,
            // ]}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            bottomLine={"border-b border-b-mediumgray pb-3 sm:pb-5"}
            MainDiveClasses={
              "bg-white lg:bg-pastalblue text-offblack w-[300px] h-[450px] sm:w-[350px] sm:h-[550px]"
            }
            pricingColor={"text-offblack"}
            label={"Premium"}
            price={getPremiumPackageDetail?.price ?? ""}
            duration={1}
            description={
              "Ideal for businesses seeking to lead and expand reach. The Premium plan offers complete solutions for dynamic growth."
            }
            buttonText={"Get started"}
            listItems={[
              `Includes all standard plan features`,
              // `Business Trading Hours`,
              `Business contact details`,
              // `highlighted services`,
              // `accepted payment methods`,
              // `social profile link`,
              // `business website link`,
              // `personalized business FAQs`,
              `10 Keywords`,
              `20 Featured posts`,
              // `20 portfolio pictures`,
              // `8 post pictures`,
              `Post metrics`,
              `Unlimited regular posts`,
            ]}
            // listItemsValue={[
            //   `${getPremiumPackageDetail?.regularListing}`,
            //   `${getPremiumPackageDetail?.featureListing}`,
            //   `${getPremiumPackageDetail?.noOfImages}`,
            // ]}
          />
        </SwiperSlide>
      </Swiper>
        <div className="flex items-center justify-center gap-x-2 mt-5">
          <CircularButton
            variant={
              location.pathname ==="/pricing-plans"
                ? "prev30White"
                : "prev30"
            }
            onClick={() => swiperRef.current?.slideNext()}
          />
          <CircularButton
            variant={
              location.pathname ==="/pricing-plans"
                ? "next30White"
                : "next30"
            }
            onClick={() => swiperRef.current?.slidePrev()}
          />
        </div>

    </div>
    </>
  );
};

export default PricingSlider;
