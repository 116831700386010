import React from "react";
// import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../components/common/Typography/SpecialText";
import { Link } from "react-router-dom";

const StoriesLayout = () => {
  const data = [
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
  ];
  return (
    <> 
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-[5%] xl:mx-auto xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] mt-10 sm:mt-20">
        {data.map((e, index) => (
          <div key={index}>
            <div className="h-auto aspect-[560/380]">
              <Link to={"/business-success-stories"}>
                <img
                  src={require("../../assets/img/default-image.jpg")}
                  className="w-full h-full rounded-3xl object-cover"
                  alt=""
                />
              </Link>
            </div>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className="text-offblack mt-2"
            >
              {e.businessname}
            </SecondaryHeading>
            <SpecialText
              variant={"OccupationLocation"}
              className={"text-offblack mt-3 sm:mt-5"}
            >
              {e.businessLocation}
            </SpecialText>
            <SpecialText variant={"Date"} className={"text-offblack mt-1"}>
              {e.businessDate}
            </SpecialText>
          </div>
        ))}
      </div>
    </>
  );
};

export default StoriesLayout;
