import React from "react";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import Heading from "../../components/common/Typography/Heading";

const Blogs = [
  {
    link: "",
    blogName: "Top 10 Plumbing Tips Every Homeowner Should Know",
    text: "Explore the boundary between DIY home repairs and tasks that require professional expertise. This guide helps homeowners identify signs that it's time to call in a professional, ensuring safety and efficiency in home maintenance.",
  },
  {
    link: "",
    blogName: "The Environmental Impact of Plumbing: What You Need to Know",
    text: "Discover practical ways to reduce water usage in your home and garden, contributing to environmental conservation and reducing your water bills. From fixing leaks to choosing water-efficient fixtures, learn how small changes can make a significant impact.",
  },
  {
    link: "",
    blogName: "How to Choose Sustainable Plumbing Solutions for Your Home",
    text: "Electrical safety is paramount in any household. This article provides an overview of your home’s electrical system, common hazards to watch for, and tips on maintaining electrical safety, highlighting when to hire a professional electrician.",
  },
  {
    link: "",
    blogName: "How to Choose Sustainable Plumbing Solutions for Your Home",
    text: "Electrical safety is paramount in any household. This article provides an overview of your home’s electrical system, common hazards to watch for, and tips on maintaining electrical safety, highlighting when to hire a professional electrician.",
  },
];
const BlogsLg = [
  {
    link: "",
    blogName: "Top 10 Plumbing Tips Every Homeowner Should Know",
    text: "Explore the boundary between DIY home repairs and tasks that require professional expertise. This guide helps homeowners identify signs that it's time to call in a professional, ensuring safety and efficiency in home maintenance.",
  },
  {
    link: "",
    blogName: "The Environmental Impact of Plumbing: What You Need to Know",
    text: "Discover practical ways to reduce water usage in your home and garden, contributing to environmental conservation and reducing your water bills. From fixing leaks to choosing water-efficient fixtures, learn how small changes can make a significant impact.",
  },
  {
    link: "",
    blogName: "How to Choose Sustainable Plumbing Solutions for Your Home",
    text: "Electrical safety is paramount in any household. This article provides an overview of your home’s electrical system, common hazards to watch for, and tips on maintaining electrical safety, highlighting when to hire a professional electrician.",
  }
];

const RelatedArticles = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Related articles
        </Heading>
      </div>

        {/* For Mobile Sreens */}
      <div className="lg:hidden flex justify-center mx-[5%] lg:mx-auto lg:w-[900px] mt-7 sm:mt-10 lg:mt-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mx-auto w-fit gap-8 text-ellipsis overflow-hidden h-[600px] sm:h-auto">
          {Blogs.map((e, index) => {
            return (
              <BlogCard
                link={e.link}
                key={index}
                blogName={e.blogName.split(/\s+/).slice(0, 15).join(" ")}
                text={e.text}
                blogTitle={e.blogTitle}
              />
            );
          })}
        </div>
      </div>

      {/* For Lg screens */}
      <div className="hidden lg:flex justify-center mx-[5%] lg:mx-auto lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-20">
        <div className="grid lg:grid-cols-3 mx-auto w-fit gap-8">
          {BlogsLg.map((e, index) => {
            return (
              <BlogCard
                link={e.link}
                key={index}
                blogName={e.blogName.split(/\s+/).slice(0, 15).join(" ")}
                text={e.text}
                blogTitle={e.blogTitle}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RelatedArticles;
