/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Filters from "../../sections/Service/Filters";

const SelectedCity = ({ formik }) => {
  return (
    <>
     <div className="mx-[5%] md:mx-auto md:w-[700px] lg:w-[980px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] pt-24">
        <Filters />
      </div>
    </>
  );
};

export default SelectedCity;
