import React, { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import { Link, useNavigate } from "react-router-dom";
import BusinessFAQs from "./BusinessFAQs";
import { MultiSelect } from "primereact/multiselect";
import { useSelector } from "react-redux";
import useAuth from "../../../Hooks/useAuth";
import TextFiled from "../../../components/common/InputField/TextFiled";
import TextArea from "../../../components/common/InputField/TextArea";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import { Chips } from "primereact/chips";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import SpecialText from "../../../components/common/Typography/SpecialText";

const Account = ({ formik }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);
  let [ServieTags, setServiceTags] = useState([]);
  const cities = [
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
  ];
  const ServieTagList = useSelector((tags) => tags.ServiceTagsReducer);
  const BusinessServiceHandler = (e) => {
    let list = [];
    for (let i = 0; i < e.target.value.length; i++) {
      let tags = ServieTagList?.filter(
        (item) => item.businessCategoryId === e.target.value[i]
      )?.map((d) => {
        return d.tagName;
      });

      list = [...list, ...tags];
    }
    formik.setFieldValue("serviceTags", list);
    formik.setFieldValue("businessService", e.target.value);
  };

  return (
    <>
      <form>
        <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto">
          <div>
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"ABN"}
              placeholder={"Enter your Australian Business Number (ABN)"}
              star={true}
            />
            <div className="flex items-center justify-end w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] text-primaryblue mt-2">
              <Link to={"https://abr.business.gov.au/"} target="_blank">
                <InteractiveText variant={"ActionLink"}>
                  Look up (ABN)
                </InteractiveText>
              </Link>
            </div>
          </div>
          <div className="mb-5 mt-1">
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Company name"}
              placeholder={"Enter the legal name of your company (Optional)"}
            />
          </div>
          <div>
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Business name"}
              placeholder={"Enter the name under which your business operates"}
              star={true}
            />
          </div>
          <div className="mt-4">
            <div className="flex">
              <Snippet
                variant={"label"}
                snippet={true}
                text={
                  "Select the main category that best represents your business, for example, carpentry."
                }
                label={"Primary business category"}
              />
              <i class="fi fi-rs-medical-star text-offblack text-[6px] ml-1"></i>
            </div>
            <div className="custom-multiselect">
              <MultiSelect
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cities}
                selectionLimit={1}
                showSelectAll={false}
                // filter
                // filterBy="name"
                optionLabel="name"
                placeholder="Select business category"
                display="chip"
                style={{
                  border: "none",
                  color: "rgb(35 42 46)",
                }}
                panelClassName="custom-multiselect-panel"
                className={`custom-multiselect-bg text-offblack items-center focus:border block font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] py-1 px-2.5 `}
              />
              <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-multiselect-icon"></i>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex">
              <Snippet
                variant={"label"}
                snippet={true}
                text={
                  "Select up to two secondary categories that further define your business, for example, framing or finished carpentry."
                }
                label={"Secondary business category"}
              />
              <i class="fi fi-rs-medical-star text-offblack text-[6px] ml-1"></i>
            </div>
            <div className="custom-multiselect">
              <MultiSelect
                value={selectedCountries}
                options={cities}
                onChange={(e) => setSelectedCountries(e.value)}
                maxSelectedLabels={3}
                selectionLimit={2}
                showSelectAll={false}
                // filter
                // filterBy="name"
                optionLabel="name"
                placeholder="Select secondary business category"
                display="chip"
                style={{
                  border: "none",
                  backgroundColor: "rgb(232 240 255)",
                  color: "rgb(35 42 46)",
                }}
                panelClassName="custom-multiselect-panel"
                className={`custom-multiselect-bg text-offblack items-center focus:border block font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] py-1 px-2.5 `}
              />
              <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-multiselect-icon"></i>
            </div>
          </div>
          {/* <div className="my-5">
                <label className="block mb-2">
                  <SecondaryHeading variant={"Subheadingsmall"}>
                    Established on
                  </SecondaryHeading>
                </label>
                <div className="flex items-center">
                  <div className="mr-[15px]">
                    <TextFiled variant={"extrasmall02"} placeholder={"Dec"} />
                  </div>
                  <div>
                    <TextFiled variant={"extrasmall02"} placeholder={"YYYY"} />
                  </div>
                </div>
              </div> */}
          <div className="mt-5">
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Location"}
              placeholder={"Type your Suburb or post code"}
              star={true}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Office number"}
              placeholder={"Enter your office phone number"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Mobile number"}
              placeholder={"Enter your business mobile number"}
              star={true}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Business email"}
              placeholder={"Enter your business email address"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Business Website"}
              placeholder={"Enter your business website address"}
              star={true}
            />
          </div>
          <div className="mt-5">
            <Snippet variant={"label"} label={"Skills"} star={true} />
            <Chips
              id="addyourtags"
              placeholder={"Add your Skills"}
              className="custom-chips-bg placeholder-lightgray text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] w-full min-h-[46px] sm:min-h-[50px] md:min-h-[54px] lg:min-h-[56px] rounded-lg"
              value={formik?.values?.serviceTags}
              onChange={(e) => {
                formik.setFieldValue("serviceTags", e.target.value);
                setServiceTags(e.target.value);
              }}
              separator=","
            />
          </div>
          <div className="mt-5">
            <BusinessFAQs />
          </div>
          <div className="mt-5">
            <TextArea
              className={"lg:bg-pastalblue"}
              variant={"large"}
              label={"Business description"}
              placeholder={"Provide a detailed description of your business."}
              star={true}
            />
          </div>
          <div className="flex items-center justify-end mt-10 w-full ">
            <MainButton variant={"small"}>Save</MainButton>
          </div>
        </div>
        <div className="mt-10 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] border-t border-t-mediumgray pt-10">
          <SpecialText variant={"FootNoteDisclaimer"}>
            Remember, this information is necessary to ensure the highest
            quality of service on Trades Poster. It enables customers to trust
            the professionals they hire through our platform. Thank you for your
            cooperation and understanding
          </SpecialText>
          <div className="flex items-center justify-center mt-10 w-full">
            <MainButton
              variant={"specialredbuttonmedium"}
              onClick={() =>
                navigate("/business-dashboard/account-deactivation")
              }
            >
              Deactivate
            </MainButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default Account;
