import React from "react";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";
import Heading from "../../components/common/Typography/Heading";
import FollowingLayout from "../../sections/CustomerDashboard/Dashboard/Following/FollowingLayout";

const Following = () => {
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-offblack text-center"}>
          People you follow/unfollow
        </Heading>
        <div className="mt-[60px] min-h-screen">
          <FollowingLayout />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Following;
