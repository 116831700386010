import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Heading from "../../components/common/Typography/Heading";
import Snippet from "../../components/common/SnippetIcon/Snippet";
import MainButton from "../../components/common/MainButton/MainButton";

const HelpCenterLayout = () => {
  const { section } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] pt-28 sm:pt-48">
        {/* Help Centre Header */}

        <Heading variant={"h1"} className="text-center w-full text-offblack">
          Help Centre
        </Heading>
        <div className="md:max-w-[800px] md:mx-auto xl:w-[800px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] h-[100px] mt-10 sm:mt-20">
          <div className="relative w-full h-[50px] sm:h-[50px] md:h-[54px] lg:h-[80px] xl:h-[100px] 2xl:h-[100px]">
            <Snippet
              variant={"label"}
              label={"Search our knowledge base or browse categories below"}
              star={false}
            />
            <input
              className={`pl-[60px] bg-white focus:border focus:border-brightblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[56px] lg:h-[66px] block p-4`}
              placeholder="Need assistance? Search your question"
              maxlength="150"
            />
            <div className="absolute left-5 -mt-9 lg:-mt-10">
              <i
                className={
                  "fi fi-rs-search text-base text-offblack flex items-center"
                }
              />
            </div>
          </div>
        </div>

        {/* Render nested routes within Outlet */}
        <Outlet />
      </div>

      {/* Contact Section at the bottom */}
      {!location.pathname.includes("/HelpCentre/contact-us") && (
        <>
          <div className="bg-white rounded-lg mt-10 sm:mt-20 w-[350px] h-[200px] sm:w-[400px] sm:h-[200px] xl:w-[616px] xl:h-[200px] mx-auto flex items-center justify-center">
            <div>
              <Heading variant={"h6"} className="text-center">
                Do you need additional assistance?
              </Heading>
              <div className="flex justify-center">
                <MainButton
                  variant={"large"}
                  className={"mt-10"}
                  onClick={() => navigate("/HelpCentre/contact-us")}
                >
                  Contact Us
                </MainButton>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HelpCenterLayout;
