import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const CheckList = () => {
  const data = [
    {
      heading: "Check licensing and certification",
    },
    {
      heading: "Verify insurance",
    },
    {
      heading: "Experience and Specialization",
    },
    {
      heading: "References and Reviews",
    },
    {
      heading: "Cost transparency",
    },
    {
      heading: "Availability and response time",
    },
    {
      heading: "Warranty and Guarantees",
    },
    {
      heading: "Local knowledge",
    },
    {
      heading: "Communication skills",
    },
    {
      heading: "Compliance with standards",
    },
    {
      heading: "Communication skills",
    },
  ];
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1150px] 2xl:w-[1440px] 3xl:w-[1600px] 2k:w-[1750px] 4k:w-[1900px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Checklist for hiring the right plumber in Australia
        </Heading>
      </div>
      <div className="mx-[5%] xl:mx-auto h-[2000px] sm:h-[1200px] md:h-[1000px] lg:h-[700px] xl:w-[1150px] xl:h-[800px] 2xl:w-[1440px] 2xl:h-[900px] 3xl:w-[1600px] 3xl:h-[1000px] 2k:w-[1750px] 2k:h-[1100px] 4k:w-[1900px] 4k:h-[1200px] bg-about bg-no-repeat bg-center bg-cover rounded-3xl mt-10 relative flex items-center">
        <div className="absolute w-full h-auto p-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 lg:mt-10">
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="bg-white rounded-xl p-5 flex items-center"
                >
                  <div>
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={"text-offblack"}
                    >
                      {item.heading}
                    </SecondaryHeading>
                    <Paragraph
                      variant={"MainParagraph"}
                      className={"text-offblack mt-3"}
                    >
                      Checklist for hiring the right plumber in Australia
                      Checklist for hiring the right plumber in Australia
                    </Paragraph>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <img
          src={require("../../assets/img/default-image.jpg")}
          alt="nothing found"
          className="w-full h-full rounded-3xl shrink-0 object-cover"
        /> */}
      </div>
    </>
  );
};

export default CheckList;
