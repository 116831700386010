import React from "react";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";

const TradingHours = () => {
  return (
    <>
      <form>
        <div className="grid grid-cols-12 w-fit mx-auto">
          <div className="col-span-12">
            <div>
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Mon - Fri"}
                placeholder={"9:00 AM - 5:00 PM"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Saturday"}
                placeholder={"10:00 AM - 3:00 PM"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Sunday"}
                placeholder={"Closed"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Public holiday"}
                placeholder={"Closed"}
              />
            </div>
            <div className="flex items-center justify-end mt-10 w-full">
              <MainButton
                className={"lg:bg-pastalblue"}
                variant={"small"}
                type="button"
              >
                Save
              </MainButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default TradingHours;
