import React from "react";
import Heading from "../../components/common/Typography/Heading";
import BeforeAfterTransition from "./BeforeAfterTransition";

const BeforeAfter = () => {

  return (
    <>
      <div className={"mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40 2xl:mt-40"}>
        <Heading variant={"h2"} className={"text-offblack"}>
          Earn trust with every job
        </Heading>
      </div>
        <BeforeAfterTransition/>
    </>
  );
};

export default BeforeAfter;
