import React from "react";
import Heading from "../../components/common/Typography/Heading";
import { businessCategories } from "../Home/BusinessCategories";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import { Link } from "react-router-dom";
import { classNames } from "primereact/utils";
const AllServicesLayout = () => {
  const handleItemSelect = (id) =>
    document
      .getElementById(`service-${id}`)
      .scrollIntoView({ block: "start", behavior: "smooth" });
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
        <Heading
          variant={"h1"}
          className={
            "text-center pt-28 lg:pt-48 text-offblack"
          }
        >
          Comprehensive service guide
        </Heading>
        <div className="flex gap-16 mt-20">
          <div className="w-full lg:flex flex-col items-start mt-[75px] hidden">
            <AuxiliaryText
              variant={"MenuDropdown"}
              className={
                "mb-5 text-offblack border-b border-b-mediumgray w-full pb-4"
              }
            >
              All Categories
            </AuxiliaryText>
            {businessCategories
              .sort((a, b) =>
                a.businessCategoryName.localeCompare(b.businessCategoryName)
              )
              .map((e) => {
                return (
                  <button
                    key={e.id}
                    type="button"
                    onClick={() => handleItemSelect(e.id)}
                  >
                    <AuxiliaryText
                      variant={"SubMenuDropdown"}
                      className={"mb-2 hover:text-primaryblue"}
                    >
                      {e.businessCategoryName}
                    </AuxiliaryText>
                  </button>
                );
              })}
          </div>
          <div>
            {businessCategories
              .sort((a, b) =>
                a.businessCategoryName.localeCompare(b.businessCategoryName)
              )
              .map((item, index) => {
                return (
                  <>
                    <div
                      id={`service-${item.id}`}
                      className={classNames({ "pt-10 lg:pt-40": index > 0 })}
                    >
                      <Heading variant={"h5"} className={"text-offblack"}>
                        {item?.businessCategoryName}
                      </Heading>
                      <div className="shrink-0 h-auto 2xl:h-[600px] 3xl:h-[700px] 2k:h-[800px] 4k:h-[900px] aspect-[900/600] mt-7 sm:mt-10">
                        <Link to={"/category-details"}>
                          <img
                            src={require("../../../src/assets/img/default-image.jpg")}
                            className="rounded-3xl w-full h-full object-cover"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllServicesLayout;
