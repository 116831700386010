import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SecondaryHeading from "../../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../../components/common/Typography/SpecialText";
import InteractiveText from "../../../components/common/Typography/InteractiveText";

const HelpCenterDetails = () => {
  const navigate = useNavigate();
  const { state = {} } = useLocation();
  const {
    displayContent = "",
    displayTitle = "",
    displayList = [],
    displayList2 = [],
    displayList3 = [],
    displayList4 = [],
    displayList5 = [],
    displayListLabel = "",
    displayListLabel2 = "",
    displayListLabel3 = "",
    displayListLabel4 = "",
    displayListLabel5 = "",
    displayInlineHeadingList = [],
    displayFooterNote = "",
    displayExtraParagraph = "",
    link = "",
    title = "",
    displayDropdowntitle = "",
  } = state;
  return (
    <>
      <div className="md:mx-auto md:max-w-[800px] xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] border-b border-b-mediumgray px-5 lg:px-[50px] lg:pb-10">
        <span
          onClick={() => {
            navigate(link, {
              state: state,
              replace: true,
            });
          }}
        >
          <SecondaryHeading
            variant={"MainParagraphHeading"}
            className={"text-offblack mt-10"}
          >
            Back to &nbsp;
            {title ? (
              <Link className="text-offblack border-b border-mediumgray hover:text-primaryblue pb-1">
                {title}
              </Link>
            ) : (
              <Link className="text-offblack border-b border-mediumgray hover:text-primaryblue pb-1">
                {displayDropdowntitle}
              </Link>
            )}
          </SecondaryHeading>
        </span>
        <InteractiveText
          variant={"FAQHeader"}
          className="mb-3 text-offblack mt-10"
        >
          {displayTitle}
        </InteractiveText>
        <InteractiveText variant={"FAQAnswer"} className={"text-offblack"}>
          {displayContent}
        </InteractiveText>
        <InteractiveText variant={"FAQAnswer"} className={"mt-5 text-offblack"}>
          {displayExtraParagraph}
        </InteractiveText>
        <ul className="ml-5">
          {displayInlineHeadingList &&
            displayInlineHeadingList.length > 0 &&
            displayInlineHeadingList.map((e) => {
              return (
                <>
                  <li>
                    <div className="mt-3">
                      <div className="flex items-center gap-2">
                        <i className="fi fi-ss-bullet text-xs text-offblack "></i>
                        <InteractiveText
                          variant={"FAQAnswer"}
                          className={"font-semibold text-offblack"}
                        >
                          {e.darkHeading}&nbsp;
                        </InteractiveText>
                      </div>
                      <InteractiveText
                        variant={"FAQAnswer"}
                        className={"text-offblack mt-3 ml-5"}
                      >
                        {e.InlineParagraph}
                      </InteractiveText>
                    </div>
                  </li>
                </>
              );
            })}
        </ul>
        <InteractiveText
          variant={"FAQAnswer"}
          className={"mb-3 mt-5 text-offblack"}
        >
          {displayListLabel}
        </InteractiveText>
        <ul className="ml-5">
          {/* Map through the list array */}
          {displayList &&
            displayList.length > 0 &&
            displayList.map((item, index) => (
              <li key={index} className="flex capitalize mt-3">
                <i className="fi fi-ss-bullet text-xs text-offblack mt-[2px]"></i>
                <InteractiveText variant={"FAQAnswer"} className={"capitalize"}>
                  &nbsp; {item.listItem}
                </InteractiveText>
              </li>
            ))}
        </ul>
        <InteractiveText
          variant={"FAQAnswer"}
          className={"mt-10 mb-3 text-offblack"}
        >
          {displayListLabel2}
        </InteractiveText>
        <ul className="ml-5">
          {/* Map through the list array */}
          {displayList2 &&
            displayList2.length > 0 &&
            displayList2.map((item, index) => (
              <li key={index} className="flex capitalize mt-3">
                <i className="fi fi-ss-bullet text-xs text-offblack mt-[2px]"></i>
                <InteractiveText variant={"FAQAnswer"} className={"capitalize"}>
                  &nbsp; {item.listItem2}
                </InteractiveText>
              </li>
            ))}
        </ul>
        <InteractiveText variant={"FAQAnswer"} className={"mt-10 mb-3 "}>
          {displayListLabel3}
        </InteractiveText>
        <ul className="ml-5">
          {/* Map through the list array */}
          {displayList3 &&
            displayList3.length > 0 &&
            displayList3.map((item, index) => (
              <li key={index} className="flex capitalize mt-3">
                <i className="fi fi-ss-bullet text-xs text-offblack mt-[2px]"></i>
                <InteractiveText variant={"FAQAnswer"} className={"capitalize"}>
                  &nbsp; {item.listItem3}
                </InteractiveText>
              </li>
            ))}
        </ul>
        <InteractiveText variant={"FAQAnswer"} className={"mt-10 mb-3 "}>
          {displayListLabel4}
        </InteractiveText>
        <ul className="ml-5">
          {/* Map through the list array */}
          {displayList4 &&
            displayList4.length > 0 &&
            displayList4.map((item, index) => (
              <li key={index} className="flex capitalize mt-3">
                <i className="fi fi-ss-bullet text-xs text-offblack mt-[2px]"></i>
                <InteractiveText variant={"FAQAnswer"} className={"capitalize"}>
                  &nbsp; {item.listItem4}
                </InteractiveText>
              </li>
            ))}
        </ul>
        <InteractiveText variant={"FAQAnswer"} className={"mt-10 mb-3 "}>
          {displayListLabel5}
        </InteractiveText>
        <ul className="ml-5">
          {/* Map through the list array */}
          {displayList5 &&
            displayList5.length > 0 &&
            displayList5.map((item, index) => (
              <li key={index} className="flex capitalize mt-3">
                <i className="fi fi-ss-bullet text-xs text-offblack mt-[2px]"></i>
                <InteractiveText variant={"FAQAnswer"} className={"capitalize"}>
                  &nbsp; {item.listItem5}
                </InteractiveText>
              </li>
            ))}
        </ul>
        <SpecialText variant={"FootNoteDisclaimer"} className={"mt-5"}>
          {displayFooterNote}
        </SpecialText>
      </div>
      <div className="mx-auto md:max-w-[800px] xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] mt-5 flex items-center">
        <div>
          <SpecialText
            variant={"DateStamp"}
            className={"flex items-center text-offblack"}
          >
            8 months ago
          </SpecialText>
        </div>
        <div className="mx-2">
          <i className="fi fi-ss-bullet text-[10px] text-offblack"></i>
        </div>
        <div>
          <SpecialText
            variant={"DateStamp"}
            className={"flex items-center text-offblack"}
          >
            Updated
          </SpecialText>
        </div>
      </div>
    </>
  );
};

export default HelpCenterDetails;
