import React from "react";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { useSelector } from "react-redux";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";

const DefineCategory = ({ formik, UserBusinessCategories }) => {
  // let [ListingTags, setListingTags] = useState([]);
  const ListingCategory = UserBusinessCategories?.data?.key?.$values?.map(
    (item) => {
      return {
        value: item.id,
        label: item.businessCategoryName,
      };
    }
  );
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
  ];
  const [selectedCity1, setSelectedCity1] = useState(null);
  const subcities = [
    { name: "Sub Air condition & Heating" },
    { name: "Sub Bricklaying" },
    { name: "Sub Handyman" },
    { name: "Sub Waterproofing" },
    { name: "Sub Plumber" },
    { name: "Sub Electrician" },
    { name: "Sub Carpentry" },
    { name: "Sub Inspection & Surveying" },
    {
      name : "Other"
    }
  ];

  const ServieTagList = useSelector((tags) => tags.ServiceTagsReducer);
  const BusinessServiceHandler = (e) => {
    let tags = ServieTagList?.filter(
      (item) => item.businessCategoryId === e.target.value
    )?.map((d) => {
      return d.tagName;
    });
    formik.setFieldValue("listingTags", tags);
    formik.setFieldValue("listCategory", e.target.value);
  };
  return (
    <>
      <form>
        <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-[60px]">
          <div className="col-span-12">
            <div>
              <Snippet
                variant={"label"}
                label={"Select Primary Post Category"}
                text={
                  "Select your primary post category from the available options."
                }
                snippet={true}
              />
              <div className="custom-dropdown">
                <Dropdown
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  placeholder="Post primary category"
                  panelClassName="custom-dropdown-panel"
                  className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]"
                />
                <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
              </div>
            </div>
            <div className="mt-5">
              <Snippet
                variant={"label"}
                label={"Select Post Sub Category"}
                text={
                  "Select your post sub category from the available options."
                }
                snippet={true}
              />
              <div className="custom-dropdown">
                <Dropdown
                  value={selectedCity1}
                  onChange={(e) => setSelectedCity1(e.value)}
                  options={subcities}
                  optionLabel="name"
                  placeholder="Post sub category"
                  panelClassName="custom-dropdown-panel"
                  className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] xl:h-[56px] 2xl:h-[56px]"
                />
                <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default DefineCategory;
