import React from "react";
import StoriesLayout from "../sections/BusinessStories/StoriesLayout";
import Banner from "../components/common/Banners/Banner";

const BusinessStories = () => {
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 2k:w-[1400px] 4k:w-[1600px]"
        }
        heading={"Business Main Page"}
        className={"bg-about"}
      />
      <StoriesLayout />
    </>
  );
};

export default BusinessStories;
