import React from "react";
import Heading from "../../components/common/Typography/Heading";
// import FeaturesSlider from "./FeaturesSlider";
import { businessCategories } from "../../sections/Home/BusinessCategories";
import { Link } from "react-router-dom";

const TradesSentricFeatures = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Maximize your efficiency with trade-centric features
        </Heading>
      </div>
      <div className="mx-[5%] xl:mx-auto h-auto xl:w-[1200px] 2xl:w-[1748px] 2xl:h-[1000px] 3xl:w-[1850px] 3xl:h-[1100px] 2k:w-[2000px] 2k:h-[1200px] 4k:w-[2150px] 4k:h-[1300px] p-5 sm:p-10 lg:p-40 rounded-3xl mt-7 sm:mt-10 relative flex items-center justify-center bg-about bg-no-repeat bg-center bg-cover">
        <div className="flex items-center justify-center gap-5 sm:gap-[40px] md:gap-[45px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px] w-full h-full mx-auto">
          <div className="flex flex-col gap-5 sm:gap-[40px] md:gap-[45px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(0, 3).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 sm:gap-[40px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(3, 8).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 sm:gap-[40px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(8, 15).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 sm:gap-[40px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(15, 22).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 sm:gap-[40px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(22, 29).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 sm:gap-[40px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(29, 34).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 sm:gap-[40px] 2xl:gap-[60px] 2k:gap-[70px] 4k:gap-[80px]">
            {businessCategories.slice(34, 37).map((e, index) => {
              return (
                <Link
                  to={`/services/?id=${e.id}`}
                  key={index}
                  //   className="absolute"
                >
                  <img
                    src={e?.categoryImage}
                    className={`shrink-0 size-10 md:size-[45px] lg:size-[50px] xl:size-[60px] 2xl:size-[70px]`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      {/* <FeaturesSlider /> */}
    </>
  );
};

export default TradesSentricFeatures;
