import React, { useState } from "react";
import Paragraph from "../../../../components/common/Typography/Paragraph";
import SpecialText from "../../../../components/common/Typography/SpecialText";
import { Link } from "react-router-dom";
import ButtonText from "../../../../components/common/Typography/ButtonText";
const NotificationLayout = () => {
  const [unread, setUnread] = useState(false);
  const [all, setAll] = useState(true);
  const Data = [
    {
      reviewMessage:
        "Ensure seamless transactions and keep your business running smoothly with our straightforward billing section. Add/ remove payment methods, track and maintain record of all your pricing plans transactions with Trades Poster",
      serviceName: "trades poster 1",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
    {
      reviewMessage:
        "Ensure seamless transactions and keep your business running smoothly with our straightforward billing section. your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 2",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Read",
    },
    {
      reviewMessage:
        "Ensure seamless transactions and keep your track and maintain record of all your pricing plans transactions with Trades Poster your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 3",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
    {
      reviewMessage:
        "Ensure Add/ remove payment methods, track and maintain record of all your pricing plans transactions with Trades Poster your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 4",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Read",
    },
    {
      reviewMessage:
        "Ensure seamless transactions and keep your business running of all your pricing plans transactions with Trades Poster your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 5",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Read",
    },
  ];
  const UnreadData = [
    {
      reviewMessage:
        "Ensure seamless transactions and keep your business running smoothly with our straightforward billing section. Add/ remove payment methods, track and maintain record of all your pricing plans transactions with Trades Poster",
      serviceName: "trades poster 1",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
    {
      reviewMessage:
        "Ensure seamless transactions and keep your business running smoothly with our straightforward billing section. your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 2",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
    {
      reviewMessage:
        "Ensure seamless transactions and keep your track and maintain record of all your pricing plans transactions with Trades Poster your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 3",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
    {
      reviewMessage:
        "Ensure Add/ remove payment methods, track and maintain record of all your pricing plans transactions with Trades Poster your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 4",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
    {
      reviewMessage:
        "Ensure seamless transactions and keep your business running of all your pricing plans transactions with Trades Poster your business running smoothly with our straightforward billing section.",
      serviceName: "trades poster 5",
      starRating: 3,
      reviewDate: "1 hour ago",
      reviewBy: "Name here",
      status: "Unread",
    },
  ];
  return (
    <div className="lg:w-[600px] xl:w-[700px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] lg:mx-auto">
      <div className="flex items-center justify-start gap-3 mb-10">
        <button
          type="button"
          className={
            all
              ? "bg-pastalblue text-primaryblue px-2 py-1 rounded-3xl"
              : "text-mediumgray px-2 py-1 rounded-3xl hover:bg-pastalblue hover:text-offblack"
          }
          onClick={() => {
            setAll(true);
            setUnread(false);
          }}
        >
          <ButtonText variant={"SecondaryText"}>All</ButtonText>
        </button>
        <button
          type="button"
          className={
            unread
              ? "bg-pastalblue text-primaryblue px-2 py-1 rounded-3xl"
              : "text-mediumgray px-2 py-1 rounded-3xl hover:bg-pastalblue hover:text-offblack"
          }
          onClick={() => {
            setAll(false);
            setUnread(true);
          }}
        >
          <ButtonText variant={"SecondaryText"}>Unread</ButtonText>
        </button>
      </div>
      <div className="border-t border-t-mediumgray">
        {all && Data?.map((item, index) => <All data={item} key={index} />)}
        {unread &&
          UnreadData?.map((item, index) => <All data={item} key={index} />)}
      </div>
    </div>
  );
};

export const All = ({ data }) => {
  return (
    <>
      <Link to={"/customer-dashboard/dashboard"}>
        <div
          // onClick={() => {
          //   setHeading(data.serviceName);
          //   setParagraph(data.reviewMessage);
          //   setOpen(true);
          // }}
          className={`${
            data.status === "Read"
              ? "cursor-pointer px-3 py-2 border-b border-b-mediumgray"
              : "bg-white lg:bg-pastalblue cursor-pointer p-3 border-b border-b-mediumgray"
          }`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <img
                src={require("../../../../assets/img/default-image.jpg")}
                className="w-9 h-9 rounded-full object-cover"
                alt="profile"
              />
              <SpecialText
                variant={"ProfileName"}
                className={"hover:text-primaryblue cursor-pointer"}
              >
                {data.serviceName}
              </SpecialText>
            </div>
            <div>
              <SpecialText variant={"DateStamp"} className={"text-offblack"}>
                {data.reviewDate}
              </SpecialText>
            </div>
          </div>
          <Paragraph variant={"MainParagraph"} className={"mt-2"}>
            {data.reviewMessage}
          </Paragraph>
        </div>
      </Link>
    </>
  );
};

export default NotificationLayout;
