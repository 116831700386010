import React from "react";
import PosterReview from "../../../PosterProfile/PosterReviews";
import RatingStars from "../../../../components/common/RatingStars/RatingStars";
import PosterRanking from "../../../PosterProfile/PosterRanking";
import SecondaryHeading from "../../../../components/common/Typography/SecondaryHeading";
import Heading from "../../../../components/common/Typography/Heading";
import AuxiliaryText from "../../../../components/common/Typography/AuxiliaryText";

const ReviewsLayout = ({ showMeReply }) => {
  const ReviewItems = [
    {
      text: "Communication",
    },
    {
      text: "Reliability",
    },
    {
      text: "Satisfaction",
    },
    {
      text: "Recommend",
    },
    {
      text: "Punctuality",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-12 lg:mx-auto lg:w-[600px] xl:w-[700px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px]">
        <div className="col-span-12">
          <div className="lg:hidden">
            <div className="mt-10 md:mt-0">
            <div className="bg-white lg:bg-pastalblue text-offblack rounded-2xl px-10 md:px-20 py-10">
                <div className="flex justify-between mb-5">
                  <div>
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Overall rating
                    </SecondaryHeading>
                  </div>
                  <div>
                    <div className="flex items-center mr-1 gap-3 h-[20px]">
                      {/* <Rating
                        value={4}
                        stars={1}
                        style={{ gap: 5 }}
                        cancel={false}
                        defaultValue={0}
                      /> */}
                      <Heading variant={"h3"}>4.1</Heading>
                    </div>
                  </div>
                </div>
                {ReviewItems?.map((item) => {
                  return (
                    <>
                      <div className="flex items-center justify-between mt-1">
                        <AuxiliaryText variant={"Placeholder"}>
                          {item?.text}
                        </AuxiliaryText>
                        {/* <Paragraph variant={"Main"}></Paragraph> */}
                        <RatingStars rating={3} />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="hidden lg:block">
            <PosterRanking show={true} />
          </div>
          <PosterReview
            showMeReply={false}
            showMeReport={false}
            DropdownClasses={"bg-white lg:bg-pastalblue"}
          />
        </div>
      </div>
    </>
  );
};

export default ReviewsLayout;
