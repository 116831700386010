import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";
import Photo from "../../assets/img/User.png";
import MainButton from "../../components/common/MainButton/MainButton";
import SpecialText from "../../components/common/Typography/SpecialText";

const PeopleMatter = () => {
  const data = [
    {
      text: "Being part of TradesPoster has revolutionized my view of what a tech team can achieve. The synergy in our front-end group, where creativity meets functionality, is simply outstanding. ",
      name: "John vick ",
      position: "Frontend Developer",
      pic: Photo,
    },
    {
      text: "As a senior full-stack developer. Bridging the gap between front-end elegance and back-end functionality.",
      name: "John vick ",
      position: "Senior Full Stack Developer ",
      pic: Photo,
    },
    {
      text: "I'm proud to streamline our operations, ensuring our platforms run smoothly and resiliently, supporting both our team and users across Australian landscape.",
      name: "John vick ",
      position: "DevOps Engineer ",
      pic: Photo,
    },
    {
      text: "Creativity flows boundlessly here, challenging and expanding my artistic horizons every day. Being part of a vibrant team at TradesPoster allows me to weave visual narratives that resonate with our audience, bringing projects to life in ways that captivate and inspire.",
      name: "John vick ",
      position: "Illustrator",
      pic: Photo,
    },
    {
      text: "My role as a data analyst involves unravelling complex datasets to derive insights that propel our marketplace forward, ensuring we stay ahead in a competitive landscape.",
      name: "John vick ",
      position: "Data Analyst",
      pic: Photo,
    },
  ];
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 lg:mt-40 xl:mt-40 2xl:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          People matters
        </Heading>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-16 mt-10 lg:mt-20 xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          {data.map((item, index) => {
            return (
              <>
                <div key={index} className="flex flex-col">
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"text-offblack"}
                  >
                    {item.text}
                  </Paragraph>
                  <div className="flex items-center mt-5">
                    <div>
                      <img
                        src={item.pic}
                        className="w-[50px] h-[50px] rounded-xl"
                        alt={item.position}
                      />
                    </div>
                    <div className="ml-3">
                      <SpecialText
                        variant={"ProfileName"}
                        className={"text-offblack"}
                      >
                        {item.name}
                      </SpecialText>
                      <SpecialText
                        variant={"OccupationLocation"}
                        className={"text-offblack"}
                      >
                        {item.position}
                      </SpecialText>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="flex justify-center">
          <MainButton variant={"extralarge"} className={"mt-10"}>
            careers at Tradesposter
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default PeopleMatter;
