import React from "react";
import PriceCard from "../../components/common/PriceCard/PriceCard";
import Heading from "../../components/common/Typography/Heading";

const PricingGuide = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mt-10 sm:mt-40">
        <div>
          <Heading variant={"h3"} className={"text-offblack"}>
            Upgrade to Trades Poster premium plan today and unlock the full
            potential of your business. Gain visibility, build trust, and drive
            more engagement.
          </Heading>
        </div>
        <div className="flex justify-center mt-10">
          <PriceCard
            tagging={true}
            bottomLine={"border-b border-b-lightgray pb-5"}
            MainDiveClasses={"bg-white text-offblack w-[350px] h-[550px] sm:w-[450px] sm:h-[600px] lg:h-[650px] xl:w-[450px] xl:h-[600px] 2xl:w-[450px] 2xl:h-[700px] 3xl:w-[550px] 3xl:h-[700px]"}
            pricingColor={"text-offblack"}
            label={"Premium"}
            price={"00"}
            duration={1}
            description={
              "Ideal for businesses seeking to lead and expand reach. The Premium plan offers complete solutions for dynamic growth."
            }
            buttonText={"Upgrade now"}
            listItems={[
              `Priority Placement in Search Results `,
              `Unlimited regular posts`,
              `20 Featured posts`,
              `10 Keywords`,
              `Priority visibility in services`,
              `10x more visibility in listings`,
              `Enhanced profile presentation`,
              `Showcase the project work`,
              `Metrics & Analytics `,
            ]}
            
          />
        </div>
      </div>
    </>
  );
};

export default PricingGuide;
