import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";

const LastSection = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Begin your journey
        </Heading>
        <div className="flex flex-col md:flex-row items-center gap-8 mt-7 sm:mt-10">
          <div className="w-full md:w-1/2 h-[500px] rounded-3xl bg-white p-10">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              Donec consequat, lacus vel ornare laoreet, nibh lacus blandit est,
              venenatis feugiat augue augue eu ipsum. a b c Vestibulum pulvinar
              eleifend facilisis. Aliquam a tempor orci, vitae ullamcorper Donec
              consequat, lacus vel ornare laoreet, nibh lacus blandit est,
              venenatis feugiat augue augue eu ipsum. a b c Vestibulum pulvinar
              eleifend facilisis. Aliquam a tempor orci, vitae ullamcorper
            </Paragraph>
            <img
              src={require("../../assets/img/default-image.jpg")}
              className="max-w-[300px] aspect-square rounded-3xl mt-10 mx-auto"
              alt=""
            />
          </div>
          <div className="w-full md:w-1/2 h-[500px] rounded-3xl bg-white p-10">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              Donec consequat, lacus vel ornare laoreet, nibh lacus blandit est,
              venenatis feugiat augue augue eu ipsum. a b c Vestibulum pulvinar
              eleifend facilisis. Aliquam a tempor orci, vitae ullamcorper Donec
              consequat, lacus vel ornare laoreet, nibh lacus blandit est,
              venenatis feugiat augue augue eu ipsum. a b c Vestibulum pulvinar
              eleifend facilisis. Aliquam a tempor orci, vitae ullamcorper
            </Paragraph>
            <img
              src={require("../../assets/img/default-image.jpg")}
              className="max-w-[300px] aspect-square rounded-3xl mt-10 mx-auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LastSection;
