import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Snippet from "../../components/common/SnippetIcon/Snippet";
import Paragraph from "../../components/common/Typography/Paragraph";

const PackageDetailsTable = () => {
  const data = [
    {
      content: "Business description",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Profile logo",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Live chat",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Business ABN details",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Cover Picture",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Keywords",
      free: <Paragraph variant={"MainParagraph"}>1</Paragraph>,
      standard: <Paragraph variant={"MainParagraph"}>3</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>10</Paragraph>,
    },
    {
      content: "Portfolio Gallery",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Multiple category selection",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Combine services in one profile to create more posts and visibility.",
    },
    {
      content: "Business Associations",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Featured posts",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <Paragraph variant={"MainParagraph"}>20</Paragraph>,
    },
    {
      content: "Portfolio Pictures",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <Paragraph variant={"MainParagraph"}>8</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>20</Paragraph>,
    },
    {
      content: "Post Pictures",
      free: <Paragraph variant={"MainParagraph"}>1</Paragraph>,
      standard: <Paragraph variant={"MainParagraph"}>3</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>8</Paragraph>,
    },
    {
      content: "Regular Posts",
      free: <Paragraph variant={"MainParagraph"}>1</Paragraph>,
      standard: <Paragraph variant={"MainParagraph"}>Unlimited</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>Unlimited</Paragraph>,
    },
    {
      content: "Business trading hours",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },

    {
      content: "Business contact details",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Skills",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Showcase top services to stand out and draw increased customer interest.",
    },
    {
      content: "Business payment methods",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Show payment options you accept on your profile for easy transactions.",
    },
    {
      content: "Social profile links",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Business website link",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Personalized business FAQs",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Create custom FAQs on your profile tailored to your business.",
    },
    {
      content: "Analytics & Metrics",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Track engagement and performance of your posts and profile metrics",
    },
  ];

  return (
    <>
      <>
        <div className="grid grid-cols-12 pt-10 sm:pt-20 lg:pt-40 mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] text-offblack">
          <div className="col-span-4 md:col-span-3 flex items-center justify-start">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Plans
            </SecondaryHeading>
          </div>
          <div className="col-span-2 md:col-span-3 flex items-center justify-center">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Free
            </SecondaryHeading>
          </div>
          <div className="col-span-3 md:col-span-3 flex items-center justify-center">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Standard
            </SecondaryHeading>
          </div>
          <div className="col-span-3 md:col-span-3 flex items-center justify-center">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Premium
            </SecondaryHeading>
          </div>
        </div>
        <div className="bg-pastalblue mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] mt-10 text-offblack">
          {data.map((e) => {
            return (
              <>
                <div className="grid grid-cols-12 pb-5">
                  <div className="col-span-4 md:col-span-3 flex items-center justify-start">
                    <Paragraph variant={"MainParagraph"}>{e.content}</Paragraph>
                    {e.snip && (
                      <div className="ml-2">
                        <Snippet variant={"icon"} text={e.snippText} />
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-3 flex items-center justify-center">
                    {e.free}
                  </div>
                  <div className="col-span-3 md:col-span-3 flex items-center justify-center">
                    {e.standard}
                  </div>
                  <div className="col-span-3 md:col-span-3 flex items-center justify-center">
                    {e.premium}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    </>
  );
};

export default PackageDetailsTable;
