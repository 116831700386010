import React from "react";
import Heading from "../../components/common/Typography/Heading";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";
import LineChart from "../../components/Analytics/LineChart";
import AddNew from "../../sections/BusinessDashboard/Subscriptions/Payment/AddNew";
import FollowingChart from "../../sections/BusinessDashboard/Dashboard/FollowingChart";
import LoggingSessions from "../../sections/BusinessDashboard/Dashboard/LoggingSessions";
import Reviews from "../../sections/BusinessDashboard/Dashboard/Reviews";
import { Link, useNavigate } from "react-router-dom";
import PostContainer from "../../sections/BusinessDashboard/Dashboard/PostSection/PostContainer";
import MapView from "../../components/common/Map/MapView";
import SortingButton from "../../components/common/Sorting/SortingButton";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import MainButton from "../../components/common/MainButton/MainButton";
const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Map */}
      <DashboardMainContainer className={"lg:px-5 pt-5 lg:pb-10"}>
        <Heading variant={"h6"} className={"text-offblack"}>
          Map
        </Heading>
        <div className="flex justify-center h-[300px] md:h-[350px] xl:h-[400px] 3xl:h-[450px] 2k:h-[500px] 4k:h-[550px] mt-5">
          <MapView />
        </div>
      </DashboardMainContainer>
      <DashboardMainContainer className={"lg:px-5 pt-5 lg:pb-10 mt-8"}>
        <div className="flex items-center justify-between">
          <Heading variant={"h6"} className={"text-offblack"}>
            Metrics
          </Heading>
          <SortingButton />
        </div>
        <LineChart />
      </DashboardMainContainer>
      {/* Billing */}
      <DashboardMainContainer className={"lg:px-5 pt-5 lg:pb-10 mt-8"}>
        <div className="flex items-center justify-between">
          <Heading variant={"h6"} className={"text-offblack"}>
            Billing and payments
          </Heading>
          <Link to={"/business-dashboard/subscriptions/billing-details"}>
            <InteractiveText
              variant={"ActionLink"}
              className={"text-primaryblue"}
            >
              View history
            </InteractiveText>
          </Link>
        </div>
        <div className="flex justify-center mt-5">
          <AddNew />
        </div>
      </DashboardMainContainer>
      {/* Income Breakedown and LoggingSessions */}
      <div className="flex flex-col lg:flex-row gap-8 w-full mt-8">
        <LoggingSessions />
        <FollowingChart />
      </div>

      {/* Post */}
      <div className="lg:bg-white w-full rounded-3xl pt-5 lg:px-5 lg:pb-10 mt-8">
        <div className="flex items-center justify-between">
          <Heading variant={"h6"} className={"text-offblack"}>
            Post
          </Heading>
          <MainButton
            variant={"outlinesmall"}
            onClick={() => navigate("/business-dashboard/posts/create-post")}
          >
            Create post
          </MainButton>
        </div>
        <PostContainer />
      </div>

      {/* Reviews */}
      <div className="lg:bg-white w-full rounded-3xl pt-5 lg:px-5 lg:pb-10 mt-8">
        <div className="flex items-center justify-between">
          <Heading variant={"h6"} className={"text-offblack"}>
            Reviews
          </Heading>
          <Link to={"/business-dashboard/inbox/reviews"}>
            <InteractiveText
              variant={"ActionLink"}
              className={"text-primaryblue"}
            >
              View all
            </InteractiveText>
          </Link>
        </div>
        <Reviews />
      </div>
    </>
  );
};

export default Dashboard;
