import React from "react";
import Heading from "../../components/common/Typography/Heading";

const FirstHeadingandPara = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] pt-10 sm:pt-20 lg:pt-40 xl:pt-40 2xl:pt-40">
        <div className="text-offblack">
          <div>
            <Heading variant={"h5"} className={"text-offblack"}>
              Uniting excellence across trades
            </Heading>
            <Heading variant={"h5"} className={"mt-5 text-offblack"}>
              In the vibrant tapestry of Australia's trade industry,
              TradesPoster emerges as the definitive nexus between ambition and
              expertise. Conceived from the vision of transcending ordinary
              service encounters into extraordinary collaborations, our platform
              is the crucible where projects and professionalism converge. Here,
              every handshake is the beginning of a masterpiece, every contract
              a covenant of quality.
            </Heading>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstHeadingandPara;
