import React from "react";
import FirstHeadingandPara from "../sections/AboutUs/FirstHeadingandPara";
import MeetTheOrigin from "../sections/AboutUs/MeetTheOrigin";
import PeopleMatter from "../sections/AboutUs/PeopleMatter";
import Banner from "../components/common/Banners/Banner";
import FounderMessage from "../sections/AboutUs/FounderMessage";
import CoFounderMessage from "../sections/AboutUs/CoFounderMessage";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const AboutUs = () => {
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 2k:w-[1400px] 4k:w-[1600px]"
        }
        heading={"Bringing Trades, Empowering Projects"}
        className={"bg-about bg-no-repeat bg-center bg-cover"}
      />
      <FirstHeadingandPara />
      <FounderMessage />
      <CoFounderMessage />
      <MeetTheOrigin />
      <PeopleMatter />
      <FullLengthSlider/>
    </>
  );
};

export default AboutUs;
