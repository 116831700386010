import React, { useState } from "react";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import ButtonText from "../../../components/common/Typography/ButtonText";
import MessageLayout from "../../../sections/MessageCentre/MessageLayout";
import Avtar from "../../../assets/img/default-image.jpg";
import AllChats from "../../../sections/MessageCentre/AllChats";
import ArchiveChats from "../../../sections/MessageCentre/ArchiveChats";
const CustMessages = () => {
  const [archive, setArchive] = useState(false);
  const [inbox, setInbox] = useState(true);
  const [msgOpening, setMsgOpening] = useState(1);
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        {msgOpening === 1 && (
          <Heading variant={"h6"} className={"text-center"}>
            Messages
          </Heading>
        )}
        {msgOpening === 1 && (
          <div className="lg:mx-auto lg:w-[600px] xl:w-[700px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] mt-[60px] min-h-screen">
            <div className="flex items-center justify-start gap-3 mb-10">
              <button
                type="button"
                className={
                  inbox
                    ? "bg-pastalblue text-primaryblue px-2 py-1 rounded-3xl"
                    : "text-mediumgray px-2 py-1 rounded-3xl hover:bg-pastalblue hover:text-offblack"
                }
                onClick={() => {
                  setInbox(true);
                  setArchive(false);
                }}
              >
                <ButtonText variant={"SecondaryText"}>inbox</ButtonText>
              </button>
              <button
                type="button"
                className={
                  archive
                    ? "bg-pastalblue text-primaryblue px-2 py-1 rounded-3xl"
                    : "text-mediumgray px-2 py-1 rounded-3xl hover:bg-pastalblue hover:text-offblack"
                }
                onClick={() => {
                  setInbox(false);
                  setArchive(true);
                }}
              >
                <ButtonText variant={"SecondaryText"}>Archive</ButtonText>
              </button>
            </div>
            {inbox && <AllChats profileLinkOption={true} moveToChat={() => setMsgOpening(2)} />}
            {archive && <ArchiveChats profileLinkOption={true} moveToChat={() => setMsgOpening(2)} />}
          </div>
        )}
        {msgOpening === 2 && (
          <div className="lg:mx-auto lg:w-[600px] 2xl:w-[800px] 3xl:w-[950px] min-h-screen">
            <MessageLayout
              moveBack={() => setMsgOpening(1)}
              profileImage={Avtar}
              messenger={"Aaqib Javaid"}
              businessProfileLink={true}
              customerprofileImage={Avtar}
              customermessenger={"Saim sahi"}
              timing={"34 mintues ago"}
              customerconversationText={
                "Of course, I'd be happy to! Here's a short poem for you In twilight's hush, the stars arise Whispering"
              }
              conversationText={
                "Of course, I'd be happy to! Here's a short poem for you In twilight's hush, the stars arise Whispering secrets in the skies Moonlight dances, shadows play, Night unfolds its silent sway."
              }
            />
          </div>
        )}
      </DashboardMainContainer>
    </>
  );
};

export default CustMessages;
